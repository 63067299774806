import React from "react";
import { useHistory, useParams } from "react-router-dom";

const useTest = () => {

  const { id } = useParams();

  const [test, setTest] = React.useState();

  const fetchTest = async (values) => {
    try {
      const res = await window.authFetch(`admin/tests/${id}`, {
        method: "get"
      });
      if(res.status == 200){
        const body = await res.json();
        setTest(body);
      }
    } catch (e) {
      console.log(e);
    } finally {

    }
  }

  React.useEffect(() => {
    fetchTest();
  }, []);

  return{
    test,
    setTest,
  }

}

export default useTest;
