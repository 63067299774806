import React from "react";

const Replies = ({review}) => {
    return(
        <ul>
        {
          review.ratings.map((reply, i) => {
            return(
              <div className="p-4">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <a className="pe-3" href="#">
                      <img src={reply.user.avatar} className="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img" />
                    </a>
                  </div>
                  <small className="text-muted">{reply.date} <br /> at {reply.time}</small>
                </div>
                <div className="mt-3">
                  <p className="text-muted mb-0">{reply.comment}</p>
                </div>
              </div>
            )
          })
        }
      </ul>
    )
}

export default Replies;