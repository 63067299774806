import React from "react";
import Navbar from "components/admin/Navbar.js";
import TextField from "components/BaseTextField";
import useTutorForm from "hooks/admin/useTutorForm";
import { Formik } from 'formik';
import * as Yup from "yup";


const TutorFormSchema = Yup.object().shape({
  name: Yup.string()
    .required(),
});

const TutorForm = () => {

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    tutor,
    isFetching
  } = useTutorForm();

  if(isFetching) return null;

  return(
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-title">{isEditing ? "Edit Tutor" : "New Tutor"}</h4>
                  <Formik
                   initialValues={tutor}
                   validationSchema={TutorFormSchema}
                   onSubmit={onSubmit}
                   validateOnChange={false}
                   validateOnBlur={false}
                   className="login-form mt-4"
                 >
                  {
                    ({ handleSubmit, ...form }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">

                          <TextField
                            label="Name"
                            name="name"
                            form={form}
                            asyncErrors={asyncErrors}
                           />

                          <TextField
                            label="Email"
                            name="email"
                            form={form}
                            asyncErrors={asyncErrors}
                           />

                          <TextField
                            label="Password"
                            name="password"
                            type="password"
                            form={form}
                            asyncErrors={asyncErrors}
                           />

                           <TextField
                             label="Confirm Password"
                             name="c_password"
                             type="password"
                             form={form}
                             asyncErrors={asyncErrors}
                            />

                          <div className="col-lg-12 mb-0">
                            <div className="d-grid">
                              <button type="submit" className={`btn btn-primary ${isSubmitting ? 'disabled' : ''}`}>
                                {isEditing ? "Update Tutor" : "Add Tutor"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TutorForm;
