import React from "react";
import {Link} from "react-router-dom";
import SectionForm from "views/admin/SectionForm";
import DeleteSectionForm from "components/admin/DeleteSectionForm";
import Sub from "components/admin/Sub";
import { CourseContext } from "views/admin/ShowCourse";
import CourseReviews from "components/admin/CourseReviews";
import { AppContext } from "App";

const CourseDetails = ({course, setCourse}) => {

  const {
    currentUser,
  } = React.useContext(AppContext);

  const {
    isApproved
  } = React.useContext(CourseContext);

  return(
    <div className="ms-lg-4">
     <div className="col mt-4 pt-2" id="accordions">
        <div className="component-wrapper rounded shadow">
          <div className="p-4 border-bottom">
            <h4 className="title mb-0">Course Content</h4>
          </div>

          <div className="p-4">
            <div className="accordion mt-4 pt-2" id="buyingquestion">
              {
                course.sections.map((section, i) => {

                  let deleteId = `delete-section-${section.id}`;

                  let onComplete = () => {
                    course.sections.splice(i, 1);
                    setCourse({ ...course });
                  }

                  return(
                    <div key={i} className="accordion-item rounded">
                      <SectionForm section={section} i={i} />
                      <h2 className="accordion-header" id="headingOne">
                        <div className="d-flex align-items-center">
                          {
                            !isApproved() ?
                            <button
                              data-bs-toggle="modal"
                              data-bs-target={`#section${section.id}`}
                              className="btn btn-icon"
                            >
                              <i className="uil uil-edit"></i>
                            </button>
                            : null
                          }
                          <button className="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${section.id}`} aria-expanded="false" aria-controls="collapseOne">
                            {section.title}
                          </button>
                          {
                            !isApproved() ?
                            <button
                              data-bs-toggle="modal"
                              data-bs-target={`#${deleteId}`}
                              className="btn btn-icon"
                            >
                              <i className="uil uil-trash"></i>
                            </button>
                            : null
                          }
                        </div>
                      </h2>
                      <DeleteSectionForm id={deleteId} path={`admin/sections/${section.id}`} title={section.title} onComplete={onComplete} />
                      <div id={`collapse${section.id}`} className="accordion-collapse border-0 collapse" aria-labelledby="headingOne" data-bs-parent="#buyingquestion">
                        <div className="accordion-body text-muted bg-white">
                          {
                            section.subs.map((sub, j) => <Sub key={j} sub={sub} i={j} sectionIndex={i} />)
                          }
                          <hr />
                          {
                            !isApproved() ?
                            [
                              {
                                name: "Add Video",
                                path: "videos/new"
                              },
                              {
                                name: "Add Test",
                                path: "tests/new"
                              },
                              {
                                name: "Add Article",
                                path: "articles/new"
                              }
                            ].map((link, i) => (
                              <Link key={i} to={`/admin/sections/${section.id}/${link.path}`} className="form-check" style={{color: "inherit"}}>
                                {link.name}
                              </Link>
                            ))
                            :null
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <CourseReviews
        course={course}
        currentUser={currentUser}
        setCourse={setCourse}
      />
    </div>
  )
}

export default CourseDetails;
