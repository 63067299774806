import React from "react";

import { Link, NavLink } from "react-router-dom";

import Header from "components/Header";
import Aside from "components/Aside";
import Footer from "components/Footer";

import Navbar from "components/Navbar";
import About from "components/About";

const Card = ({ category }) => (
    <a href={`${category.link}`} target="_blank" className="col-lg-3 col-md-4 mt-4 pt-2 d-block text-dark">
        <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <div className="card-body p-0 content">
                <h5>{category.name}</h5>
                {/* <p className="para text-muted mb-0"> 33,145,974 students</p> */}
            </div>
            <span className="big-icon text-center">
                <i className="uil uil-chart-line"></i>
            </span>
        </div>
    </a>
);

const Categories = ({ categories }) => (
    <section className="section">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <div className="section-title mb-4 pb-2">
                        <h4 className="title mb-4">Click Below for More Information</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    categories.map((category, i) => <Card key={i} category={category} />)
                }
            </div>
        </div>
    </section>
)

const links = [
    "/documents/Our-OBU-Mentorship-Program.pdf",
    "https://www.brookes.ac.uk/getmedia/e77b0a6e-3773-4cf5-88e7-388a9da55502/Info-Pack-June-2024-25.pdf", // info pack
    "https://www.brookes.ac.uk/business/undergraduate/bsc-applied-accounting/eligibility/", // eligible
    "https://www.brookes.ac.uk/business/undergraduate/bsc-applied-accounting/", // obu
    "https://www.brookes.ac.uk/business/undergraduate/bsc-applied-accounting/webinars-and-materials/", // obu webinar
    "https://www.youtube.com/channel/UCCwWtE2XDV4shxHo1tyZUtA/featured", // rap
    "https://www.brookes.ac.uk/business/undergraduate/bsc-applied-accounting/submissions/", // submit
    "https://www.accaglobal.com/gb/en/student/exam-entry-and-administration/oxford-brookes-bsc-hons-in-applied-accounting.html", // acca
]

const OBUResources = () => {

    React.useEffect(() => {
        window.topFunction();
        window.feather.replace();
    });

    let categories = [
        "Our Programme",
        "Info Pack",
        "Am I Eligible?",
        "OBU",
        "OBU Webinar Hub",
        "RAP Walkthrough",
        "How to Submit",
        "ACCA",
    ].map((cat, i) => ({
        name: cat,
        link: links[i],
        blank: i == 0
    }))

    return (
        <>
            <Navbar />
            <Header
                title="OBU RAP Resources"
                description="Turbocharge your career with a degree from a UK University using
                all the work you've already done in passing your ACCA papers"
            />
            <About
                title="hands-on mentorship
                    for RAP success"
                description="Take the next step towards future-proofing your
                    career using all the work you've already done - you
                    only need to submit a research project"
                options={[
                    "Passed (on track to pass) ACCA's FR, AA, and FM",
                    "Want a Fully Recognised UK Degree",
                    "Need Quality Guidance",
                ]}
                path="/obu/our-package"
                buttonText="Our Package"
            />
            <Categories categories={categories} />
            <Footer />
        </>
    )
}

export default OBUResources;
