import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";

const Navlink = ({ link }) => {

    const location = useLocation();

    const isActiveLink = () => link.path == location.pathname ? 'active' : '';

    return(
        <li className='sidebar-nav-link-item'>
            <Link  to={`/admin/${link.path}`} className={`sidebar-nav-link ${isActiveLink()}`}>
                <i className={`fa fa-${link.icon} fa-2x`}></i>
                <span>{link.name}</span>
            </Link>
        </li>
    )
}

export default Navlink;