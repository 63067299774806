import React from "react";
import {
  AppContext
} from "../App";
import {
  Link,
  useHistory,
} from "react-router-dom";
import queryString from "query-string";

const makeCategory = (name, _path) => {

  let path = `/courses?category=${name.toLowerCase()}`

  if (_path) path = _path

  return {
    name,
    path
  }

}

const Navbar = () => {

  const history = useHistory();

  const {
    count,
    currentUser,
    signOut
  } = React.useContext(AppContext);

  const isActiveCat = (cat) => {
    const {
      category
    } = queryString.parse(history.location.search);
    return category == cat;
  }

  const [categories, setCategories] = React.useState([
    makeCategory("ACCA"),
    makeCategory("OBU", "/obu"),
  ]);

  /*const fetchCategories = async () => {
    const res = await window.jsonFetch(`categories`, { });
    if(res.status == 200){
      const body = await res.json();
      setCategories(body);
    }
  }*/

  React.useEffect(() => {
    //fetchCategories();
  }, []);

  return (
    <header id="topnav" className="defaultscroll sticky tagline-height">
      <div className="container">
        <Link className="logo" to="/">
          <span className="logo-light-mode">
            <img src="/images/stor_institute1.png" className="l-dark" height="50" alt="" />
            <img src="/images/stor_institute1.png" className="l-light" height="50" alt="" />
          </span>
          <img src="/images/stor_institute1.png" height="50" className="logo-dark-mode" alt="" />
        </Link>
        <div className="buy-button">

          <Link to="/cart" className="basket btn btn-icon btn-soft-primary" >
            <i className="uil uil-shopping-cart align-middle icons"></i>
            {
              count() > 0
                ? <span>{count()}</span>
                : null
            }
          </Link>

          {
            currentUser ?
              <div className="dropdown d-inline-block">
                <button className="btn btn-icon dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <img role="avatar" src={currentUser.avatar} style={{ borderRadius: "50%", width: "35px" }} />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <Link to="/account" className="dropdown-item text-primary">Account</Link>
                  </li>
                  <li>
                    <Link to="/my_courses" className="dropdown-item text-primary">My Courses</Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <button onClick={signOut} className="dropdown-item text-danger">Logout</button>
                  </li>
                </ul>
              </div>
              :
              <>
                <Link to="/login">
                  <div className="btn btn-light">Login</div>
                </Link>

                <Link to="/sign_up" className="ms-2">
                  <div className="btn btn-primary login-btn-primary">Sign Up</div>
                  <div className="btn btn-light login-btn-light">Sign Up</div>
                </Link>
              </>
          }

        </div>
        <div className="menu-extras">
          <div className="menu-item">
            <a className="navbar-toggle" id="isToggle" onClick={() => window.toggleMenu()}>
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>

        <div id="navigation">
          <ul className="navigation-menu nav-light">
            {
              categories.map((cat, i) => {

                return (
                  <li key={i}>
                    <Link
                      to={cat.path}
                      className={`sub-menu-item ${isActiveCat(cat.name) ? 'active' : ''}`}
                    >
                      {cat.name}
                    </Link>
                  </li>
                )
              })
            }

            <li>
              <Link to={`/master_classes`} className={`sub-menu-item ${isActiveCat("/") == "/" ? 'active' : ''}`}>Masterclass</Link>
            </li>
            <li>
              <Link to={`/courses`} className={`sub-menu-item ${isActiveCat("/") == "/" ? 'active' : ''}`}>Catalogue</Link>
            </li>
          </ul>
          {
            currentUser ?
              <div className="buy-menu-btn d-none">
                <Link to="/account" style={{ paddingLeft: 0 }} className="sub-menu-item">Account</Link>
              </div>
              :
              <div className="buy-menu-btn d-none">
                <Link to="/login" className="sub-menu-item">Login</Link>
                <Link to="/sign_up" className="btn btn-primary">Sign up</Link>
              </div>
          }
        </div>
      </div>
    </header>
  )
}

export default Navbar;
