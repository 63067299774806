import React from "react";
import Navbar from "components/admin/Navbar.js";
import TextField from "components/BaseTextField";
import useNewTest from "hooks/admin/useNewTest";
import { Formik } from 'formik';
import * as Yup from "yup";
import BackButton from "components/admin/BackButton";


const TestSchema = Yup.object().shape({
  title: Yup.string()
    .required(),
  description: Yup.string()
    .required(),
  pass_mark: Yup.string()
    .required(),
});

const CourseForm = () => {

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
  } = useNewTest();

  return(
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-title">New Test</h4>
                  <Formik
                   initialValues={{
                     title: '',
                   }}
                   validationSchema={TestSchema}
                   onSubmit={onSubmit}
                   className="login-form mt-4"
                 >
                  {
                    ({ handleChange, handleSubmit, values, errors }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <TextField
                                label="Title"
                                placeholder="Test title"
                                name="title"
                                helperText={errors.title || asyncErrors.title}
                                onChange={handleChange}
                                value={values.title}
                               />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <TextField
                                label="Description"
                                placeholder="Description"
                                name="description"
                                helperText={errors.description || asyncErrors.description}
                                onChange={handleChange}
                                value={values.description}
                                multiline
                               />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <TextField
                                label="Pass Mark"
                                name="pass_mark"
                                helperText={errors.pass_mark || asyncErrors.pass_mark}
                                onChange={handleChange}
                                value={values.pass_mark}
                               />
                            </div>
                          </div>

                          <div className="col-lg-12 mb-0">
                            <div className="d-grid">
                              <button type="submit" className="btn btn-primary">Create</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }
                </Formik>
              </div>
            </div>

            <BackButton />

          </div>
        </div>
      </div>
    </>
  )
}

export default CourseForm;
