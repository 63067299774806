import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "App";

const useNewVideo = (src) => {

  const history = useHistory();

  const { id } = useParams();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [isFetching, setIsFetching] = React.useState();
  const [isEditing, setIsEditing] = React.useState();

  const [video, setVideo] = React.useState({
    name: "",
    email: "",
    password: ""
  });

  const [asyncErrors, setAsyncErrors] = React.useState({

  });

  const onSubmit = async (values) => {
    try {
      openModal();
      let file = { };
      const vid = document.getElementById("video");
      if(vid) file = vid;
      let url = isEditing ? `admin/videos/${id}` : `admin/sections/${id}/videos`;
      let method = isEditing ? "put" : "post";
      const res = await window.authFetch(url, {
        method,
        body: JSON.stringify({
          ...values,
          url: src
        })
      });
      if (res.ok) {
        const body = await res.json();
        history.push(`/admin/courses/${body.course_id}`);
      } else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  const fetchVideo = async () => {
    try {
      setIsFetching(true);
      let url = `admin/videos/${id}/edit`;
      const res = await window.authFetch(url);
      if (res.status == 200) {
        const body = await res.json();
        setVideo(body);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }

  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("edit")) {
      setIsEditing(true);
      fetchVideo();
    }
  }, []);


  return {
    onSubmit,
    asyncErrors,
    video,
    isEditing,
    isFetching,
  }

}

export default useNewVideo;
