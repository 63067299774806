const CardPage = ({ children, title }) => {
    return (
        <section className="bg-half-170 d-table w-100 h-100" data-jarallax='{"speed": 0.5}' style={{ "background": "url('/images/crypto/stor_background.png') top", backgroundSize: "cover" }}>
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-8">
                        <div className="card login-page bg-white shadow rounded border-0">
                            <div className="card-body">
                                <h4 className="card-title text-center">{title}</h4>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CardPage;