import React, { useEffect, useState } from "react";
import { AppContext } from "App";
import Rating from "components/Rating";
import { Link, useHistory } from "react-router-dom";
import useCourses from "hooks/useCourses";
import Navbar from "components/Navbar";
import CourseUp from "components/CourseUp";
import useFetch from "lib/useFetch";
import MasterClass from "./MasterClass";
import useMasterClasses from "./useMasterClasses";
import MasterClassSide from "./MasterClassSide";

const MasterClasses = () => {

    const {
        masterClasses,
        onChange,
        search,
        onSubmit,
        onChangeCategory,
        category,
        categories
    } = useMasterClasses();


    return (
        <>
            <Navbar />
            <CourseUp 
                title="Masterclass" 
                description="Join our expert instructors live in a masterclass. Live and practical
                sessions, with almost instantaneous feedback to build your skills."
            />
            <section className="section" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="sidebar sticky-bar p-4 rounded shadow">
                                <div className="widget">
                                    <form role="search" onSubmit={onSubmit}>
                                        <div className="input-group mb-3 border rounded">
                                            <input
                                                onChange={onChange}
                                                value={search}
                                                type="text"
                                                className="form-control border-0"
                                                placeholder="Search Keywords..."
                                            />
                                            <button type="submit" className="input-group-text bg-transparent border-0" id="searchsubmit">
                                                <i className="uil uil-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <div className="widget mb-4 pb-4 border-bottom">
                                    <h5 className="widget-title">Categories</h5>
                                    <div className="mt-4 mb-0">
                                        <select onChange={onChangeCategory} value={category} className="form-select form-control" aria-label="Default select example">
                                            <option value="">All Topics</option>
                                            {
                                                masterClasses.categories.map((category, i) => <option key={i}>{category.name}</option>)
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0" >
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            Time
                                        </th>
                                        <th
                                            colSpan={2}
                                        >
                                            Session
                                        </th>
                                        <th>
                                            Price
                                        </th>
                                        <th>
                                            Join
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        masterClasses.masterClasses.data.map((course, i) => <MasterClassSide
                                            course={course}
                                            index={i}
                                        />)
                                    }
                                </tbody>
                            </table>
                            {
                                masterClasses.masterClasses.meta.pagination.total_pages > 1 ?
                                    <div className="row">
                                        <div className="col-12">
                                            <ul className="pagination justify-content-center mb-0">
                                                <li className={`page-item ${masterClasses.meta.pagination.current_page == 1 ? 'disabled' : ''}`}><a className="page-link" aria-label="Previous">Prev</a></li>
                                                <li className="page-item active"><a className="page-link" href="javascript:void(0)">1</a></li>
                                                <li className="page-item"><a className="page-link" href="javascript:void(0)">2</a></li>
                                                <li className="page-item"><a className="page-link" href="javascript:void(0)">3</a></li>
                                                <li className="page-item"><a className="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MasterClasses;
