import React from 'react';
import { Link } from 'react-router-dom';
import Currentuser from './Currentuser';
import Navlink from './Navlink';
import "./sidebar.css";

const links = [
    {
        name: "Dashboard",
        path: "",
        roles: ["Super Admin", "Tutor"]
    },
    {
        name: "Courses",
        path: "courses",
        roles: ["Super Admin", "Tutor"]
    },
    {
        name: "Users",
        path: "users",
        roles: ["Super Admin"]
    },
    {
        name: "Tutors",
        path: "tutors",
        roles: ["Super Admin"]
    },
    {
        name: "Categories",
        path: "categories",
        roles: ["Super Admin"]
    },
    {
        name: "Orders",
        path: "orders",
        roles: ["Super Admin"]
    },
    {
        name: "Pages",
        path: "pages",
        roles: ["Super Admin"]
    },
    {
        name: "Master Class",
        path: "master_classes",
        roles: ["Super Admin"]
    },
    {
        name: "Taxes",
        path: "taxes",
        roles: ["Super Admin"]
    }
];

const Sidebar = ({ children, links }) => {

    const openSidebar = () => {
        const sidebar = document.querySelector("#sidebar");
        sidebar?.classList.toggle("open");
    }

    return (
        <>
            <div id="sidebar" className="position-fixed bottom-0 top-0">
                <div
                    className='d-flex justify-content-end mt-2'
                >
                    <a
                        id="isToggle"
                        className="text-white d-block d-lg-none p-4"
                        onClick={openSidebar}
                    >
                        <i className='fa fa-close fa-1x'></i>
                    </a>
                </div>
                <div className="p-2">
                    <a className="d-block" href="/">
                        <img src="/logo.png" />
                    </a>

                </div>
                <ul className="list-unstyled" style={{ padding: "20px 0 20px 20px" }}>
                    {
                        links.map((link, i) => <Navlink link={link} key={i} />)
                    }
                </ul>
            </div>
            <div id="main">
                <div id="navbar">
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <div className="menu-extras">
                                <div className="menu-item">
                                    <a className="navbar-toggle" id="isToggle" onClick={openSidebar}>
                                        <div className="lines">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Currentuser />
                    </div>
                </div>
                <div className="" style={{ marginTop: "20px" }}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default Sidebar;

export const withSidebar = (Component) => {
    return (props) => {
        return (
            <>
                <Sidebar
                    links={links}
                >
                    <Component {...props} />
                </Sidebar>
            </>
        )
    }
} 