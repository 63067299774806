import React from "react";
import queryString from "query-string";

const useCourses = () => {

  const [search, setSearch] = React.useState("");
  const [category, setCategory] = React.useState("");

  const onChange = ({target}) => setSearch(target.value);

  const onChangeCategory = ({target}) => setCategory(target.value);

  const onSubmit = (e) => {
    e.preventDefault();
    fetchCourses();
  }

  const [courses, setCourses] = React.useState({
    courses: {
      data: [],
      meta: {
        pagination: {

        }
      }
    },
    categories: []
  });

  const fetchCourses = async () => {
    const res = await window.authFetch(`courses?filter[search]=${search}&filter[category]=${category}`);
    if(res.status == 200){
      const body = await res.json();
      setCourses(body);
    }
  }

  React.useEffect(() => {
    if(courses.categories.length > 0){
      const {
        category
      } = queryString.parse(window.location.search);
      if(category){
        let cat = courses.categories.filter((cat) => cat.name.toLowerCase() == category.toLocaleLowerCase());
        setCategory(cat[0].name);
      }
    }
  }, [courses.categories.length]);

  React.useEffect(() => {
    fetchCourses();
  }, [category]);

  return{
    courses,
    search,
    onChange,
    onSubmit,
    onChangeCategory,
    category
  }
}

export default useCourses;
