import React from "react";
import CourseUp from "components/CourseUp";

import Video from "./Video";
import Test from "./Test";
import Article from "./ShowArticle";
import useSub from "hooks/useSub";
import Navbar from "components/Navbar";

const Sub = () => {

  const {
    sub,
    completed
  } = useSub();

  if(!sub) return null;

  let Body = null;
  if(sub.attachable_type.includes("Video")){
    Body = () => <Video completed={completed} sub={sub} />;
  }else if (sub.attachable_type.includes("Test")) {
    Body = () => <Test questions={sub.questions} sub={sub} />;
  }else if (sub.attachable_type.includes("Article")) {
    Body = () => <Article sub={sub} completed={completed} />;
  }

  return(
    <>
      <Navbar />
      <CourseUp title={sub.title} />
      <Body />
    </>
  )
}

export default Sub;
