import React from "react";
import useOrders from "hooks/useOrders";
import { Course } from "views/Courses";
import Navbar from "components/Navbar";
import MasterClass from "./master_classes/MasterClass";

const Orders = () => {

  const {
    orders
  } = useOrders();

  return (
    <>
      <Navbar />
      <section className="bg-profile d-table w-100 bg-primary" style={{ "background": "url('images/account/bg.png') center center" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className=" public-profile " style={{ "zIndex": 1 }}>
                <div className="card-body">
                  <div className="row align-items-center">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" >
        <div className="container">
          <div className="row">

            <div className="col-lg-8 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0" >
              <div className="row">
                {
                  orders.map((orderItem, i) => {
                    if (orderItem.isMasterclass) {
                      return <MasterClass
                        key={i}
                        course={orderItem.orderable}
                        index={i}
                      />
                    } else {
                      return <Course
                        key={i}
                        course={orderItem.orderable}
                        index={i}
                      />
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Orders;
