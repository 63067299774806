import React from "react";
import Page from "components/Page";

/*
const makeDate = (title, date) => ({ title, date })

const OBUDates = ({ title, dates }) => {
    return (
        <>
            <p class="mb-2 text-center">
                <strong class="bold-text-12">{title}</strong>
            </p>
            <hr />
            <ul role="list" class="list-3">
                {
                    dates.map((date, i) => (

                        <li key={i}>{`${date.title} :-: `}<strong>{date.date}</strong></li>
                    ))
                }

            </ul>
        </>
    )
}
*/

const OBUPackage = () => {

    React.useEffect(() => {
        window.topFunction();
        window.feather.replace();
    });

    return (
        <Page
            slug="our-package"
        />
    )
}

export default OBUPackage;