import React from "react";
import useCourse from "hooks/admin/useCourse";
import Navbar from "components/admin/Navbar.js";
import {Link} from "react-router-dom";
import TextField from "components/BaseTextField";
import Button from "components/BaseButton";
import CourseInformation from "components/admin/CourseInformation";
import {AppContext} from "App";
import CourseUp from "components/admin/CourseUp";
import Editable from "components/admin/Editable";
import CourseDetails from "components/admin/CourseDetails";
import SectionForm from "views/admin/SectionForm";

export const CourseContext = React.createContext();

const Course = () => {

  const {
    currentUser,
    isAdmin,
    openModal,
    closeModal,
    openErrorDialog,
  } = React.useContext(AppContext);

  const courseHook = useCourse();
  const [courseForm, setCourseForm] = React.useState({

  });

  const {
    course,
    setCourse,
    onApprove
  } = courseHook;

  React.useEffect(() => {
    if(course) setCourseForm(course)
  }, [course]);

  if(!course) return null;

  const onChange = ({name, value}) => setCourseForm({ ...courseForm, [name]: value });

  const onSave = async () => {
    try {
      openModal();
      let url = `admin/courses/${course.id}`;
      const res = await window.authFetch(url, {
        method: "put",
        body: JSON.stringify(courseForm)
      });
      if(res.ok){
        const body = await res.json();
        setCourse({...body});
      }else if (res.status == 422) {
        const body = await res.json();
      }
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  };

  const isApproved = () => course.state == "Approved";


  const courseContext = {
    ...courseHook,
    onChange,
    isApproved,
  }

  return (
    <CourseContext.Provider value={courseContext}>
      <CourseUp title={course.title} />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-12">
              <CourseInformation course={course} />
            </div>
            <div className="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="ms-lg-4">
                <h5>Course Description: </h5>
                <Editable
                  placeholder="Description"
                  multiline
                  Componet="p"
                  ComponetProps={{className: "text-muted description"}}
                  text={course.description}
                  rows={10}
                />
              </div>
              <CourseDetails course={course} setCourse={setCourse} />
              <div className="mt-5 d-flex align-items-center">
                {
                  !isApproved() ?
                  <>
                  <button
                    className="btn btn-primary"
                    onClick={onSave}
                  >
                    Save Changes
                  </button>
                  {
                    course.state != "Approved" && isAdmin() ?
                      <button className="btn btn-outline-primary ms-4"
                        onClick={onApprove}
                      >
                        Approve Course
                      </button>
                    :
                      null
                  }
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#createSection"
                    className="btn btn-outline-primary ms-4"
                  >
                    New Section
                  </button>
                  </>
                  : null
                }
              </div>
            </div>
          </div>

        </div>
        <SectionForm />
      </section>
    </CourseContext.Provider>
  );
}

export default Course;
