import React from "react";
import CourseUp from "components/CourseUp";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw } from 'draft-js';
import {
  Link,
} from "react-router-dom";
import {AppContext} from "App";
import BackButton from "components/BackButton";
import {VideoSidebar} from "views/Video";
import Button from "components/BaseButton";
import {Prev, Next} from "components/Nav";

const Article = ({ sub: article , completed}) => {

  let [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  React.useEffect(() => {
    if(!article) return;
    const raw = JSON.parse(article.body);
    const eS = convertFromRaw(raw);
    setEditorState(EditorState.createWithContent(eS));
  }, [article]);

  if(!article) return null;

  const {
    attachable_id,
    title,
    description,
    body,
    state
  } = article;

  return(
    <section className="section">
      <div className="vicontainer course-text-wrapper card shadow rounded border-0 overflow-hidden pt-0">
        <VideoSidebar video={article} />
          <button class="show-content" onClick={window.onCloseTextVideoSidebar}>
            Open Course Content
          </button>
          <div className="vicontainer-text-wrapper">
            <div class="vicontainer-text-wrapper-inner">
              <div class="vicontainer-text-wrapper-inner-prev">
                <Prev
                  sections={article.sections}
                  sub_id={article.id}
                  section_id={article.section_id}
                />
              </div>
              <div class="vicontainer-text-wrapper-inner-content">
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  readOnly
                  toolbarHidden
                />
                <div className=" d-flex mt-4">
                  <BackButton id={article.course_id} />
                  {
                    !article.completed ?
                      <button onClick={completed} className="btn btn-primary ms-4">Completed</button>
                    : null
                  }
                </div>
              </div>
              <div class="vicontainer-text-wrapper-inner-next">
                <Next
                  sections={article.sections}
                  sub_id={article.id}
                  section_id={article.section_id}
                />
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Article;
