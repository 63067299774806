import React from "react";
import Navbar from "components/admin/Navbar";
import TextField from "components/BaseTextField";
import useCourseForm from "hooks/admin/useCourseForm";
import { Formik } from 'formik';
import * as Yup from "yup";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import handleSetImage from "lib/handleSetImage";
import {AppContext} from "App";

const CourseFormSchema = Yup.object().shape({
  title: Yup.string()
    .required(),

  description: Yup.string()
    .required(),
  price: Yup.number()
      .required(),
  duration: Yup.string()
      .required(),
});

const CourseForm = () => {

  const {
    currentUser
  } = React.useContext(AppContext);

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
    categories,
    tutors,
    isEditing,
    crop,
    setCrop,
    image,
    setImage,
    src,
    setSrc,
    course,
    isFetching
  } = useCourseForm();

  const onSetImage = ({target}) => handleSetImage({target, setSrc});

  if(isFetching) return null;

  return(
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-title">{isEditing ? "Edit Course" : "New Course"}</h4>
                  <Formik
                   initialValues={course}
                   validationSchema={CourseFormSchema}
                   onSubmit={onSubmit}
                   validateOnChange={false}
                   validateOnBlur={false}
                   className="login-form mt-4"
                 >
                  {
                    ({ handleSubmit, ...form  }) => {

                      const {
                        values,
                        handleChange
                      } = form;
                      return <form onSubmit={handleSubmit}>
                        <div className="row">

                          {
                            src ?
                            <>
                              <ReactCrop
                                src={src}
                                crop={crop}
                                onChange={setCrop}
                                onImageLoaded={setImage}
                              />

                              <div style={{position: "relative"}} className="my-4">
                                <button type="submit" className="btn btn-primary">Upload cover image</button>
                                <input 
                                  type="file" 
                                  onChange={onSetImage} 
                                  name="image" 
                                  style={{position: "absolute", top: 0, left: 0 , bottom: 0, right: 0, opacity: 0}} 
                                  accept="image/*"
                                />
                              </div>
                            </>
                            :
                            <>
                              <div style={{position: "relative"}} className="my-4">
                                <img src={course.image || "/placeholder.jpg"} />
                                <input 
                                  type="file" 
                                  onChange={onSetImage} 
                                  name="image" 
                                  style={{position: "absolute", top: 0, left: 0 , bottom: 0, right: 0, opacity: 0}} 
                                  accept="image/*"
                                />
                              </div>
                            </>
                          }

                          <TextField
                            name="title"
                            form={form}
                            asyncErrors={asyncErrors}
                            label="Title"
                           />

                          <TextField
                            name="code"
                            form={form}
                            asyncErrors={asyncErrors}
                            label="Code"
                           />

                          <TextField
                            name="description"
                            form={form}
                            asyncErrors={asyncErrors}
                            label="Description"
                            multiline
                           />

                          <TextField
                            name="price"
                            form={form}
                            asyncErrors={asyncErrors}
                            label="Price"
                           />

                          <TextField
                            name="duration"
                            form={form}
                            asyncErrors={asyncErrors}
                            label="Duration"
                           />

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Category <span className="text-danger">*</span></label>
                              <div className="form-icon position-relative">
                                <select
                                  name="category_id"
                                  className="form-control"
                                  autocomplete="off"
                                  onChange={handleChange}
                                  value={values.category_id}
                                >
                                  <option value="">Select Category</option>
                                  {
                                    categories.map((category, i) => <option key={i} value={category.id}>{category.name}</option>)
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          {
                            currentUser.role == "Super Admin" ?
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label className="form-label">Tutor <span className="text-danger">*</span></label>
                                <div className="form-icon position-relative">
                                  <select
                                    name="tutor_id"
                                    className="form-control"
                                    autocomplete="off"
                                    onChange={handleChange}
                                    value={values.tutor_id}
                                  >
                                    <option value="">Select Tutor</option>
                                    {
                                      tutors.map((tutor, i) => <option key={i} value={tutor.id}>{tutor.name}</option>)
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                            : null
                          }

                          <div className="col-lg-12 mb-0">
                            <div className="d-grid">
                              <button type="submit" className="btn btn-primary">{isEditing ? "Update Course" : "Add Course"}</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    }
                  }
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CourseForm;
