import React from "react";

import { Link, NavLink } from "react-router-dom";

import Header from "components/Header";
import Aside from "components/Aside";
import Footer from "components/Footer";

import Navbar from "components/Navbar";
import { Once } from "views/Home";
import About from "components/About";

const OBU = () => {

    React.useEffect(() => {
        window.topFunction();
        window.feather.replace();
    });

    return (
        <>
            <Navbar />
            <Header
                title="OBU BSC (Hons) in Applied Accounting"
                description="Turbocharge your career with a degree from a UK University using
                all the work you've already done in passing your ACCA papers"
            />
            <About
                title="hands-on mentorship
                    for RAP success"
                description="Take the next step towards future-proofing your
                    career using all the work you've already done - you
                    only need to submit a research project"
                options={[
                    "Passed (on track to pass) ACCA’s FR, AA, and FM",
                    "Want a Fully Recognised UK Degree",
                    "Need Quality Guidance",
                ]}
                path="/obu/resources"
                buttonText="More Info"
            />
            <div className="section">
                <div className="container">
                    <div>
                        <h1>Welcome</h1>
                        <p>If you are looking for a mentor to help you navigate the tricky Research and Analysis Project (RAP), then you
                            have come to the right place!</p>
                    </div>
                    <div style={{ marginTop: "60px" }}>
                        <h1>The Oxford Brookes University RAP</h1>
                        <p>The OBU project is different to anything you may have done in your ACCA exams. Even if you already hold a
                            degree, this RAP needs to be taken seriously if you want to pass first time and to avoid all the challenges,
                            which come with a resubmission.</p>
                    </div>
                </div>
            </div>
            <Once
                text="Having passed the RAP ourselves, and received the OBU degree,
                we know what our mentees need and are here to support you
                every step of the way"
            />
            <Footer />
        </>
    )
}

export default OBU;