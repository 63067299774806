import React from "react";
import TextField from "@material-ui/core/TextField";

const BaseTextField = React.forwardRef(({
  label,
  form = {
    errors: {

    },
    values: {

    }
  },
  name,
  asyncErrors = {},
  Icon,
  required,
  multiline,
  size = "12",
  error,
  helperText,
  ...others
}, ref) => {

  let Component = multiline ? "textarea" : "input";
  if(!error) error = Boolean(form.errors[name] || asyncErrors[name]);
  if(!helperText) helperText = form.errors[name] || asyncErrors[name];

  return(
    <div className={`col-lg-${size}`}>
      <div className="mb-3">
        {
          label ?
          <label className="form-label">{label}
            {
              required ?
              <span className="text-danger">*</span>
              : null
            }
          </label>
          :null
        }
        <div className="form-icon position-relative">
          {
            Icon ?
            <i data-feather={Icon} className="fea icon-sm icons"></i>
            : null
          }
            <Component
              placeholder={label}
              ref={ref}
              name={name}
              className={`form-control ${Icon ? 'ps-5' : ''} ${error ? 'border-danger' : ''}`}
              value={form.values[name]}
              onChange={form.handleChange}
              {...others}
              autoComplete="off"
            >
            </Component>
          {
            helperText ?
              <p className={`ms-3 mt-1 ${error ? 'text-danger' : ''}`}>{helperText}</p>
            : null
          }
        </div>
      </div>
    </div>
  )
})

export default BaseTextField;
