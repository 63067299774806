import React from "react";
import {
  Link
} from "react-router-dom";
import { Prev, Next } from "components/admin/Nav";
import { VideoSidebar } from "views/admin/Video";
import CourseUp from "components/CourseUp";
import Button from "components/BaseButton";
import useTest from "hooks/admin/useTest";
import Navbar from "components/admin/Navbar";
import QuestionForm from "components/admin/QuestionForm";
import Answer from "components/admin/Answer";

const Test = () => {

  const testHook = useTest();

  const {
    test,
  } = testHook;

  if(!test) return null;

  let sub = test;

  const {
    questions
  } = test;

  return(
    <>
      <CourseUp title={test.title} />
      <section className="section">
      <div className="vicontainer course-text-wrapper card shadow rounded border-0 overflow-hidden pt-0">
        <VideoSidebar video={sub} />
        <button class="show-content" onClick={window.onCloseTextVideoSidebar}>
          Open Course Content
        </button>
        <div className="vicontainer-text-wrapper">
          <div class="vicontainer-text-wrapper-inner">
            <div class="vicontainer-text-wrapper-inner-prev">
              <Prev
                sections={sub.sections}
                sub_id={sub.id}
                section_id={sub.section_id}
              />
            </div>
            <div class="vicontainer-text-wrapper-inner-content">
            <ol style={{listStyleType: "decimal", padding: 0}}>
                 {
                   questions.map((question, i) => {
                     const id = `question-${question.id}`
                     return(
                       <li className="mt-3">
                         <div className="d-flex justify-content-between">
                           <h4 className="title mb-0">{question.body} </h4>
                           {
                             test.state !== "Approved" ?
                             <button
                               className="btn btn-icon"
                               data-bs-toggle="modal"
                               data-bs-target={`#${id}`}
                              >
                               <i className="uil uil-edit"></i>
                             </button>
                             : null
                           }
                         </div>
                         <QuestionForm
                           id={id}
                           test={testHook}
                           q={question}
                           i={i}
                         />
                         <ol style={{listStyleType: "lower-alpha"}}>
                           {
                             question.answers.map((answer, i) => <Answer
                                body={answer.body}
                                isChecked={answer.id == question.answer_id}
                              />)
                           }
                         </ol>
                      </li>
                     )
                   })
                 }
                 {
                   test.state != "Approved" ?
                     <>
                       <QuestionForm
                         id="createQuestion"
                         test={{
                           ...testHook,
                           test: {
                             ...testHook.test,
                             id: testHook.test.attachable_id
                           }
                         }}
                         />
                       <div className="mt-4">
                       <button
                         className="btn btn-primary"
                         data-bs-toggle="modal"
                         data-bs-target={`#createQuestion`}
                        >
                         New Question
                        </button>
                      </div>
                     </>
                     :null
                 }
               </ol>
               <Link to={`/admin/courses/${test.course_id}`} className="btn btn-outline-primary">Back</Link>
            </div>
            <div class="vicontainer-text-wrapper-inner-next">
              <Next
                sections={sub.sections}
                sub_id={sub.id}
                section_id={sub.section_id}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Test;
