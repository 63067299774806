import React from "react";
import queryString from "query-string";
import { useHistory } from "react-router";

export default () => {

    const history = useHistory();

    const confirmPayment = async (orderId) => {
        const parsed = queryString.parse(window.location.search);
        const {
            TransactionToken
        } = parsed;
        const res = await global.authFetch(`orders/${orderId}/payment`, {
            method: "put",
            body: JSON.stringify(
                {
                    TransToken: TransactionToken,
                }
            )
        });
        if (res.status == 200) {
            localStorage.removeItem("order_id");
            history.push("/my_courses");
        }else if(res.status == 400){
            setTimeout(() => {
                confirmPayment(orderId);
            }, 2000)
        }
    }

    React.useEffect(() => {
        const orderId = localStorage.getItem("order_id");
        confirmPayment(orderId);
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
                <div className="loader"></div>
                <div className="mt-4">
                    <p>Verifying payment</p>
                </div>
            </div>
        </div>
    )
}