import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

export let host = `${process.env.REACT_APP_URL}/api`;

let jsonFetch = (path, {headers = {}, ...others}) => fetch(`${host}/${path}`, {
  mode: "cors",
  headers: {
    "Accept": "application/json",
    "content-type": "application/json",
    ...headers,
  },
  ...others
});
let authFetch = (path, options = {}) => {
  const {headers = { }, ...others} = options;
  const token = localStorage.getItem("token");
  return jsonFetch(path, {
    headers: {
      "Authorization": `Bearer ${token}`,
      ...headers,
    },
    ...others
  })
}

window.host = host;
window.authFetch = authFetch;
window.jsonFetch = jsonFetch;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
