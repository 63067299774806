import Navbar from "components/admin/Navbar";
import Table from "components/admin/Table";
import BaseTextField from "components/BaseTextField";
import useFetch from "lib/useFetch";
import { useEffect, useState, createRef } from "react";
import { Link } from "react-router-dom";


const Component = ({ row, setRows, i, rows }) => {

    const id = `update-link-${row.id}`;

    const ref = createRef();

    const onUpdateSucess = async (body) => {
        rows[i] = body;
        setRows([...rows]);
        var myModal = window.bootstrap.Modal.getInstance(document.getElementById(id));
        myModal.hide();
    }

    const {
        onPut,
    } = useFetch(`/admin/master_classes/${row.id}/update_link`, onUpdateSucess);

    const onClick = () => {
        onPut({
            link: ref.current.value
        });
    }


    return(
        <div id={id} className="modal fade" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Update link</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body">
                        <BaseTextField
                            label="Link"
                            ref={ref}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={onClick}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MasterClasses = () => {

    const [masterClasses, setMasterClasses] = useState([]);

    const {
        onFetch,
    } = useFetch("/admin/master_classes", setMasterClasses);

    useEffect(() => {
        onFetch();
    }, [])

    const onClick = async (id, i) => {
        const res = await global.authFetch(`admin/master_classes/${id}/approve`, {
            method: "PUT",
        });
        if(res.ok){
            const body = await res.json();
            masterClasses[i] = body;
            setMasterClasses([...masterClasses]);
        }
    }

    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h2>Master Classes</h2>
                    <div>
                        <Link to="/admin/master_classes/create" className="btn btn-primary login-btn-primary">New Master Class</Link>
                    </div>
                </div>
                <Table
                    headers={[
                        { title: "Title" },
                        { title: "Price" },
                        { title: "State" },
                        { title: "Link" },
                        { title: "Start Date", key: "started_at" },
                    ]}
                    rows={masterClasses}
                    resource="master_classes"
                    actions={true}
                    show={false}
                    addOnActions={
                        [
                            {
                                callback: (row, i) => {
                                    if(row.state !== "Approved"){
                                        return {
                                            onClick: async () => {
                                                onClick(row.id, i);
                                            },
                                            name: "Approve"
                                        }
                                    }
                                    return null;
                                }
                            },
                            {
                                "name": "Update link",
                                onClick: (row) => {
                                    let modalEl = document.getElementById(`update-link-${row.id}`);
                                    let modal = new window.bootstrap.Modal(modalEl, {});
                                    modal.show()
                                }
                            }
                        ]
                    }
                    AddOnRow={Component}
                />
            </div>
        </>
    );
}

export default MasterClasses;