import React from "react";
import Navbar from "components/admin/Navbar";
import useTaxes from "./useTaxes";
import Table from "components/admin/Table";
import { Link } from "react-router-dom";

const Taxes = () => {

    const {
        taxes
    } = useTaxes();

    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h2>Taxes</h2>
                    <div>
                        <Link to="/admin/taxes/create" className="btn btn-primary login-btn-primary">New Tax</Link>
                    </div>
                </div>
                <Table
                    headers={[
                        { title: "Name" },
                        { title: "Percent" },
                    ]}
                    rows={taxes}
                    actions
                    resource="taxes"
                    show={false}
                />
            </div>
        </>
    )
}

export default Taxes;