import React from "react";
import { useParams } from "react-router-dom";
import {AppContext} from "App";

const useCourse = () => {

  const { id } = useParams();

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [course, setCourse] = React.useState();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const onApprove = async (e) => {
    try {
      openModal();
      const res = await window.authFetch(`admin/courses/${course.id}/approve`, {
        method: "put",
      });
      if(res.status == 200){
        const body = await res.json();
        setCourse({ ...body });
      }else throw new Error();
    } catch (e) {
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  const fetchCourse = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/courses/${id}`);
      if(res.status == 200){
        const body = await res.json();
        setCourse(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchCourse();
  }, []);


  return{
    course,
    setCourse,
    onApprove
  }

}

export default useCourse;
