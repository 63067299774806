import React from "react";
import { useHistory, useParams } from "react-router-dom";
import toBase64 from "lib/toBase64";
import { AppContext } from "App";
import moment from "moment";
import useFetch from "lib/useFetch";

const useMasterClassForm = () => {

  const history = useHistory();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [crop, setCrop] = React.useState({
    aspect: 16 / 9,
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const [src, setSrc] = React.useState();

  const [image, setImage] = React.useState();

  const [isSubmitting, setIsSubmitting] = React.useState();
  const [isFetching, setIsFetching] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState();

  const [categories, setCategories] = React.useState([]);

  const [masterClass, setMasterClass] = React.useState({
    title: "",
    description: "",
    price: "",
    time_at: moment().format("hh:mm"),
    start_date: moment(),
    meridiem: "PM",
    more: "",
  });

  const [asyncErrors, setAsyncErrors] = React.useState({});

  const { id } = useParams();

  const onNewCourse = (body) => {
    setCategories(body.categories);
    setIsFetching(false);
  }

  const {
    onFetch: newCourse
  } = useFetch("/admin/courses/create", onNewCourse)

  const editMasterClass = async () => {
    try {
      let url = `admin/master_classes/${id}/edit`;
      const res = await window.authFetch(url);
      if (res.status == 200) {
        const body = await res.json();
        setMasterClass(body.masterclass);
        setCategories(body.categories);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }

  React.useEffect(() => {
    if (isEditing) editMasterClass();
  }, [isEditing]);

  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("edit")) {
      setIsEditing(true);
    } else newCourse();
  }, []);

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      openModal();
      const {
        start_date,
        time_at,
        meridiem,
      } = values;
      let s = moment(start_date).format("M/DD/YYYY");
      let started_at = moment(`${s} T ${time_at} ${meridiem}`, "M/DD/YYYY T hh:mm A");
      const file = toBase64(image, crop);
      let url = isEditing ? `admin/master_classes/${id}` : `admin/master_classes`;
      const res = await window.authFetch(url, {
        method: isEditing ? "put" : "post",
        body: JSON.stringify({
          ...values,
          started_at,
          file
        })
      });
      if (res.ok) {
        history.push(`/admin/master_classes`);
      } else if (res.status == 422) {
        const body = await res.json();
        setAsyncErrors({ ...body });
      } else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      setIsSubmitting(false);
      closeModal();
    }
  }


  return {
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    crop,
    setCrop,
    image,
    setImage,
    src,
    setSrc,
    masterClass,
    isFetching,
    categories
  }

}

export default useMasterClassForm;
