import React from "react";
import {
  Link
} from "react-router-dom";
import {
  generatePath
} from "react-router";
import {AppContext} from "App";

const TableRow = ({
  headers,
  rows,
  title,
  resource,
  actions,
  show = true,
  row,
  i,
  setRows,
  addOnActions,
  edit = true,
}) => {

  let id = `delete-${resource}-${row.id}`;

  const {
    openModal,
    closeModal,
    openErrorDialog,
  } = React.useContext(AppContext);

  let onDelete = async () => {
    try {
      openModal();
      const res = await window.authFetch(`admin/${resource}/${row.id}`, {
        method: "delete"
      });
      if(res.status == 200){
        rows.splice(i, 1);
        setRows([...rows]);
      }
    } catch (e) {
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  React.useEffect(() => {
    if(actions){
      var myModal = window.bootstrap.Modal.getInstance(document.getElementById(id));

    }
    return () => {
      if(actions && myModal){

        myModal.hide();
      }
    }
  }, []);


  return (
    <tr key={i}>
      <td>{row.displayId}</td>
      {
        headers.map((header, j) => {
          return <td key={j}>{row[header.key || header.title.toLowerCase()]}</td>
        })
      }
      {
        actions ?
          <td>
            <div className="dropdown d-inline-block">
              <button className="btn btn-icon dropdown-toggle" type="button" data-bs-toggle="dropdown">
                <i className="ui uil-more"></i>
              </button>
              <ul className="dropdown-menu">
                {
                  addOnActions ?
                  addOnActions.map((action, k) => {
                    if(action.callback){
                      const a = action.callback(row, i);
                      if(a == null) return null;
                      return(
                        <li key={k}>
                          <button onClick={a.onClick} className="dropdown-item">{a.name}</button>
                        </li>
                      )
                    }
                    if(action.onClick){

                      let handleClick = () => action.onClick(row);

                      return(
                        <>
                          <li key={k}>
                            <button onClick={handleClick} className="dropdown-item">{action.name}</button>
                          </li>
                        </>
                      )
                    }
                    return(
                      <li key={k}>
                        <Link to={generatePath(action.path, {  id: row.id })} className="dropdown-item">{action.name}</Link>
                      </li>
                    )
                  })
                  :null
                }
                {
                  show ?
                  <li>
                    <Link to={`/admin/${resource}/${row.id}`} className="dropdown-item">View</Link>
                  </li>
                  :null
                }
                {
                  edit ?
                  <li>
                    <Link to={`/admin/${resource}/${row.id}/edit`} className="dropdown-item">Edit</Link>
                  </li>
                  : null
                }
                <li>
                  <button
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target={`#${id}`}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div>
            <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Delete</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to delete this?
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={onDelete}>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        : null
      }
    </tr>
  )
}


export default TableRow;
