import useFetch from "lib/useFetch";
import React from "react";
import { useParams } from "react-router-dom";

const useMasterClass = () => {

  const {
    id
  } = useParams();

  const [masterClass, setMasterClass] = React.useState();

  const {
    onFetch
  } = useFetch(`/master_classes/${id}`, setMasterClass);

  React.useEffect(() => {
    onFetch();
  }, []);

  return{
    masterClass,
    setMasterClass
  }
}

export default useMasterClass;
