import React from "react";
import useSections from "hooks/admin/useSections";
import Navbar from "components/admin/Navbar";
import {Link, useParams } from "react-router-dom";
import Table from "components/admin/Table";

const Sections = () => {

  const {
    sections,
    course
  } = useSections();

  const {
    id
  } = useParams();

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2>{course.title} Sections</h2>
          <div>
            <Link to={`/admin/courses/${id}/sections/new`} className="btn btn-primary login-btn-primary">New Section</Link>
          </div>
        </div>
        <Table
          headers={[
            {title: "Title"},
            {title: "Description"},
            {title: "Subsections", key: "subs_count"},
          ]}
          rows={sections}
          resource="sections"
          actions={true}
          show={false}
        />
      </div>
    </>
  );
}

export default Sections;
