import React from "react";
import { AppContext } from "App";
import { Link, useHistory } from "react-router-dom";

const MasterClass = ({ course, index }) => {

    const {
        addToCart,
        canAddToCart,
        removeItem
    } = React.useContext(AppContext);

    const history = useHistory();

    const onAddToCart = () => addToCart(course, "masterclass");
    const onRemoveItem = () => removeItem(course, index);


    return (
        <Link to={`/masterclasses/${course.id}`} className="col-lg-4 col-12 mb-4 pb-2">
            <div className="card job-box rounded shadow border-0 overflow-hidden">
                <div className="border-bottom">
                    <div className="position-relative">
                        <img src={course.image} className="img-fluid" alt={course.title} />
                        <div className="job-overlay bg-white"></div>
                    </div>
                    <ul className="list-unstyled head mb-0">
                        <li className="badge bg-warning rounded-pill">{`$${course.price}`}</li>
                    </ul>
                </div>

                <div className="card-body content position-relative">

                    <div className="company-detail text-center mt-3">
                        <h5 className="mb-0">
                            {course.title}
                        </h5>
                        <p className="text-muted">{course.description}</p>
                    </div>
                    {
                        history.location.pathname.includes("my_courses") ?
                            null
                            :

                            <div className="d-grid">
                                {
                                    canAddToCart(course) ?
                                        <button onClick={onAddToCart} className="btn btn-outline-primary">Add to Cart</button>
                                        : (
                                            history.location.pathname.includes("cart") ?
                                                <button onClick={onRemoveItem} className="btn btn-outline-primary">Remove from Cart</button>
                                                : null
                                        )
                                }
                            </div>
                    }
                </div>
            </div>
        </Link>
    )
}

export default MasterClass;