import React from "react";
import TextField from "components/BaseTextField";
import Button from "components/BaseButton";
import Review from "./Review";

const CourseReviews = ({course, setCourse, currentUser}) => {

  const [ratings, setRatings] = React.useState();
  const ref = React.createRef();

  const review = async () => {
    const res = await window.authFetch(`courses/${course.id}/ratings`, {
      method: "post",
      body: JSON.stringify({
        body: ref.current.value,
        ratings
      })
    })
    ref.current.value = "";
    if(res.ok){
      const body = await res.json();
      course.reviews.push(body);
      setCourse({ ...course});
    }
    setRatings(0);
  }

  return(
    <div className="col-lg-12">

       <div className="rounded shadow">

          <div class="accordion-item rounded mt-2">
            <h2 class="accordion-header" id="reviewtab">
                <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReview" aria-expanded="false" aria-controls="collapseReview">
                    Reviews
                </button>
            </h2>
            <div id="collapseReview" class="accordion-collapse border-0 collapse" aria-labelledby="reviewtab" data-bs-parent="#reviewComp">
              <div class="accordion-body text-muted bg-white">
                {
                  course.reviews.map((review, i) => <Review key={i} review={review} />)
                }

                {
                  course.owned ?
                    <div className="p-4">
                      <h4 className="title mb-0">Submit Review</h4>
                      <ul className="list-unstyled h6 mb-0">
                        {
                          [...Array(5).keys()].map((i) => {

                            const onClick = () => setRatings(i + 1);

                            return(
                              <li key={i} onClick={onClick} style={{cursor: "pointer"}} className={`list-inline-item mb-0 ${(i + 1) <= ratings ? 'text-warning' : 'text-muted'}`}>
                                <i className="mdi mdi-star" style={{fontSize: "20px"}}></i>
                              </li>
                            )
                          })
                        }
                      </ul>
                      <TextField
                        ref={ref}
                        multiline
                        placeholder="Your message...."
                      />
                      <div className="d-flex">
                        <Button onClick={review}>Submit</Button>
                      </div>
                    </div>
                  : null
                }
              </div>
            </div>

         </div>

       </div>

     </div>
  )
}

export default CourseReviews;
