import { calcTotal } from "components/SubCard";
import React from "react";

const useCart = ({
  openModal,
  openErrorDialog,
  closeModal,
}) => {

  const [cart, setCart] = React.useState([]);

  const count = () => cart.length;

  const canAddToCart = (course, is_ms = false) => {
    if (course.owned) return false;
    return cart.filter((item) => item.cartable_id == course.id && item.is_ms === is_ms).length == 0;
  };

  const total = (state) => {
    let t = cart.map(cartItem => cartItem.cartable).reduce((partial_sum, a) => partial_sum + (a.price * 1), 0);
    t = calcTotal(t, state);
    return t;
  }
  const taxes = (state) => {
    let t = cart.map(cartItem => cartItem).reduce((partial_sum, a) => partial_sum + ((a.cartable.price * 1) - (a.subtotal * 1)), 0);
    t = calcTotal(t, state);
    return t;
  }

  const fetchCartItems = async () => {
    let token = localStorage.getItem("token");
    let cart = localStorage.getItem("cart_id");
    try {
      if (cart) {
        const res = await fetch(`${window.host}/cart_items?cart_id=${cart}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json"
          }
        });
        if (res.status == 200) {
          const body = await res.json();
          setCart(body);
        }
      } else {
        const res = await fetch(`${window.host}/carts`, {
          method: "post",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });
        if (res.ok) {
          const body = await res.json();
          localStorage.setItem("cart_id", body);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    fetchCartItems();
  }, []);

  const addToCart = async (item, type = "course") => {
    try {
      openModal();
      let cart_id = localStorage.getItem("cart_id");
      const res = await fetch(`${window.host}/cart_items`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          cartable_id: item.id,
          type,
          cart_id,
        })
      })
      if (res.ok) {
        const item = await res.json();
        setCart([...cart, ...[item]]);
      } else throw new Error();
    } catch (e) {
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  const removeItem = async (course) => {
    try {
      openModal();
      let cart_id = localStorage.getItem("cart_id");
      const res = await window.jsonFetch(`cart_items`, {
        method: "delete",
        body: JSON.stringify({
          cartable_id: course.id,
          cart_id,
        })
      })
      if (res.status == 200) {
        const item = cart.filter((item) => item.cartable.id == course.id)[0];
        const i = cart.indexOf(item);
        cart.splice(i, 1);
        setCart([...cart]);
      } else throw new Error();
    } catch (e) {
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  return {
    cart,
    addToCart,
    count,
    total,
    removeItem,
    canAddToCart,
    setCart,
    taxes,
  }

}

export default useCart;
