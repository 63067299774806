import React from "react";
import Button from "components/BaseButton";
import TextField from "components/BaseTextField";
import { useFormik } from 'formik';
import * as yup from 'yup';
import useChangePassword from "hooks/useChangePassword";

const validationSchema = yup.object({
  old_password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Old Password is required'),
  new_password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New Password is required'),
  c_new_password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Confirm New Password is required'),
});

const ChangePassword = () => {

  const {
    onSubmit
  } = useChangePassword();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      c_new_password: "",
    },
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return(
    <div className="row">
      <div className="col-md-12 mt-4 pt-2">
        <h5>Change password :</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="row mt-4">
            <TextField
              label="Old Password"
              placeholder="Old Password"
              type="password"
              name="old_password"
              value={formik.values.old_password}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.old_password)}
              helperText={formik.errors.old_password}
            />
            <TextField
              label="New Password"
              placeholder="New Password"
              type="password"
              name="new_password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.new_password)}
              helperText={formik.errors.new_password}
            />
            <TextField
              label="Confirm New Password"
              placeholder="Confirm New Password"
              type="password"
              name="c_new_password"
              value={formik.values.c_new_password}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.c_new_password)}
              helperText={formik.errors.c_new_password}
            />
            <div className="col-lg-12 mt-2 mb-0">
              <button type="submit" className="btn btn-primary">Save password</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword;
