import React from "react";
import Navbar from "components/admin/Navbar.js";
import TextField from "components/BaseTextField";
import useArticleForm from "hooks/admin/useArticleForm";
import { Formik } from 'formik';
import * as Yup from "yup";
import { Editor } from "react-draft-wysiwyg";
import BackButton from "components/admin/BackButton";


const ArticleFormSchema = Yup.object().shape({
  title: Yup.string()
    .required(),
});

const ArticleForm = () => {

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    article,
    isFetching,
    editorState,
    onEditorStateChange,
  } = useArticleForm();

  if(isFetching) return null;

  const getBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });

  const uploadCallback = async (file) => {
    const base64Image = await getBase64(file);
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: base64Image } });
      }
    );
  }

  return(
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-title">{isEditing ? "Edit Article" : "New Article"}</h4>
                  <Formik
                   initialValues={article}
                   validationSchema={ ArticleFormSchema}
                   onSubmit={onSubmit}
                   validateOnChange={false}
                   validateOnBlur={false}
                 >
                  {
                    ({ handleSubmit, ...form }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">

                        <TextField
                          label="Title"
                          name="title"
                          form={form}
                          asyncErrors={asyncErrors}
                         />

                         <TextField
                           label="Description"
                           name="description"
                           form={form}
                           asyncErrors={asyncErrors}
                           multiline
                          />

                        <div>
                          <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            uploadCallback={uploadCallback}
                            toolbar={{
                              image: {
                                previewImage: true
                              }
                            }}
                          />
                        </div>

                          <div className="col-lg-12 mb-0">
                            <div className="d-flex">
                              <button type="submit" className={`btn btn-primary ${isSubmitting ? 'disabled' : ''}`}>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }
                </Formik>
              </div>
            </div>
            <BackButton />
          </div>
        </div>
      </div>
    </>
  )
}

export default  ArticleForm;
