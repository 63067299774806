import React from "react";
import Navbar from "components/Navbar";
import CourseUp from "components/CourseUp";
import "./page.css";
import Footer from "./Footer";
import { useParams } from "react-router";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw } from 'draft-js';

const usePage = (_slug) => {

    const params = useParams();

    let slug = _slug || params.slug;

    const [page, setPage] = React.useState();

    const fetchPage = async (slug) => {
        const res = await window.authFetch(`pages/${slug}`);
        if (res.ok) {
            const body = await res.json();
            setPage({ ...body });
        }
    }

    React.useEffect(() => {
        fetchPage(slug);
    }, [slug]);

    return {
        page,
    }
}


const Page = ({ slug }) => {

    const {
        page
    } = usePage(slug);

    React.useEffect(() => {
        window.feather.replace();
    }, [page]);

    let [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    React.useEffect(() => {
      if(!page) return;
      if(!page.body) return;
      const raw = JSON.parse(page.body);
      const eS = convertFromRaw(raw);
      setEditorState(EditorState.createWithContent(eS));
    }, [page]);

    React.useEffect(() => {
        window.topFunction();
    })

    if(!page) return null;

    return (
        <>
            <Navbar />
            <CourseUp title={page.name} />
            <section className="section" >
                <div className="container">
                    <div className="row">
                        <div className="page">
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                readOnly
                                toolbarHidden
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Page;
