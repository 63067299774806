import React from "react";
import {AppContext} from "App";

const DeleteAccount = () => {

  const {
    deleteUser
  } = React.useContext(AppContext);

  return(
    <div className="rounded shadow mt-4">
      <div className="p-4 border-bottom">
        <h5 className="mb-0 text-danger">Delete Account :</h5>
      </div>

      <div className="p-4">
        <h6 className="mb-0">Do you want to delete the account? Please press below "Delete" button</h6>
        <div className="mt-4">
          <button className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target={`#delete-account`}
          >
            Delete Account
          </button>
        </div>
      </div>
      <div className="modal fade" id="delete-account" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                Are you sure you want to delete your account?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={deleteUser}>Yes</button>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default DeleteAccount;
