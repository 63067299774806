
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor as DraftEditor } from "react-draft-wysiwyg";
import { useEffect, useState } from "react";

const Editor = ({ onChange, body, isEditing, ...others }) => {

    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [mounted, setMounted] = useState(false);
 
    const onEditorStateChange = (props) => {
        setEditorState(props);
        let body = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        onChange(body);
    }

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (!body) return;
        if (!isEditing) return;
        if (mounted) return;
        const raw = JSON.parse(body);
        const eS = convertFromRaw(raw);
        console.log(eS);
        setEditorState(EditorState.createWithContent(eS));
        setMounted(true);
    }, [body]);

    const getBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const uploadCallback = async (file) => {
        const base64Image = await getBase64(file);
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: base64Image } });
            }
        );
    }

    return (
        <div>
            <DraftEditor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                uploadCallback={uploadCallback}
                toolbar={{
                    image: {
                        previewImage: true
                    }
                }}
                {...others}
            />
        </div>
    )
}

export default Editor;