import React from "react";
import Navbar from "components/admin/Navbar";
import Article from "components/admin/Article";

const ShowArticle = () => {

  return(
    <>
      <Article />
    </>
  )
}

export default ShowArticle;
