import React from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom";
import {AppContext} from "App";

const useCategoryForm = () => {

  const history = useHistory();

  const { id } = useParams();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [isSubmitting, setIsSubmitting] = React.useState();
  const [isFetching, setIsFetching] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState();

  const [category, setCategory] = React.useState({
    name: "",
  });

  const [asyncErrors, setAsyncErrors] = React.useState({});

  const fetchCategory = async () => {
    try {
      setIsFetching(true);
      let url = `admin/categories/${id}/edit`;
      const res = await window.authFetch(url);
      if(res.status == 200){
        const body = await res.json();
        setCategory(body);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      openModal();
      let url = isEditing ? `admin/categories/${id}` : `admin/categories`;
      let method = isEditing ? "put" : "post";
      const res = await window.authFetch(url, {
        method,
        body: JSON.stringify(values)
      });
      if(res.ok){
        history.push("/admin/categories");
      }else if (res.status == 422) {
        const body = await res.json();
        setAsyncErrors({...body});
      }else throw new Error();
    } catch (e) {
      openErrorDialog();
    } finally {
      setIsSubmitting(false);
      closeModal();
    }
  }

  React.useEffect(() => {
    const path = window.location.pathname;
    if(path.includes("edit")){
      setIsEditing(true);
      fetchCategory();
    }
  }, []);


  return{
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    category,
    isFetching
  }

}

export default useCategoryForm;
