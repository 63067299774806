const InfoBox = ({
  title,
  count,
  backgroundColor,
  icon
}) => (
  <div className="info-box">
    <span style={{backgroundColor}} className="info-box-icon elevation-1 text-white">
      <i className={icon}></i>
    </span>
    <div className="info-box-content">
      <span className="info-box-text">{title}</span>
      <span className="info-box-number">
        {count}
      </span>
    </div>
  </div>
)

export default InfoBox;
