import React from "react";
import TextField from "components/BaseTextField";


const ReplyForm = ({id, rating, course, setCourse, i}) => {

    const ref = React.createRef();
  
    const onReply = async () => {
      const res = await window.authFetch(`admin/ratings/${rating.id}`, {
        method: "post",
        body: JSON.stringify({
          body: ref.current.value,
        })
      })
      ref.current.value = "";
      if(res.ok){
        const body = await res.json();
        course.reviews[i].ratings.push(body);
        setCourse({ ...course});
      }
      var myModal = window.bootstrap.Modal.getInstance(document.getElementById(id));
      myModal.hide();
    }
  
    return(
      <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Reply</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <TextField
                ref={ref}
                multiline
                placeholder="Your message...."
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={onReply}>Reply</button>
            </div>
          </div>
        </div>
      </div>
    )
  }


  export default ReplyForm;