import { AppContext } from "App";
import useFetch from "lib/useFetch";
import { useContext, useEffect, useState } from "react";

const useSendEmail = () => {

    const [asyncErrors, setAsyncErrors] = useState({
        
    });

    const {
        setCurrentUser,
        openModal,
        closeModal,
        openErrorDialog
    } = useContext(AppContext);

    const onSuccess = () => {
    }

    const onError = async (res) => {
        if(res.status == 422){
            const body = await res.json();
            setAsyncErrors(body.errors);
        }else{
            openErrorDialog();
        }
    }

    const onFinish = () => {
        closeModal();
    }

    const {
        onPost,
    } = useFetch("/password/email", onSuccess, onError, onFinish);

    const onSubmit = (values) => {
        openModal();
        onPost({
            email: values.email
        })
    }

    return {
        onSubmit,
        asyncErrors
    }
}

export default useSendEmail;