import React from "react";

const Activity = ({ text }) => {
    return (
        <div className="modal " id={"activity2"} tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-4">
                    <div className="modal-body">
                        <div className="d-flex justify-content-center">
                            <div>
                                <div className="loader"></div>
                                <p className="mt-3">
                                    {text}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activity;
