import React from 'react';

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Home from "./views/Home";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import Cart from "./views/Cart";
import Courses from "./views/Courses";
import Account from "./views/Account";
import ShowCourse from "./views/ShowCourse";
import MyCourses from "./views/MyCourses";
import Sub from "./views/Sub";
import Checkout from "./views/Checkout";
import OBU from "./views/obu/OBU";

import Article from "./views/ShowArticle";
import Notifications from "./views/Notifications";

import {AppContext} from "App";
import Payment from 'views/Payment';
import About from 'views/About';
import Page from 'components/Page';
import OBUPackage from 'views/obu/OBUPackage';
import OBUResources from 'views/obu/OBUResources';
import MasterClasses from 'views/master_classes/MasterClasses';
import ShowMasterClass from 'views/master_classes/show/ShowMasterClass';
import SendEmail from 'views/password/send/SendEmail';
import UpdatePassword from 'views/password/update/UpdatePassword';
import { stackBuilder } from 'lib/stackBuilder';
import AdminStack from 'views/admin/Routes';
import OrderUpdate from 'views/OrderUpdate';


const stacks = [
  stackBuilder(Home, "/"),
  stackBuilder(Login, "/login"),
  stackBuilder(SignUp, "/sign_up"),
  stackBuilder(Cart, "/cart"),
  stackBuilder(Courses, "/courses"),
  stackBuilder(Account, "/account", true),
  stackBuilder(ShowCourse, "/courses/:id"),
  stackBuilder(MyCourses, "/my_courses", true),
  stackBuilder(Sub, "/subs/:id", true),
  stackBuilder(Checkout, "/checkout", true),
  stackBuilder(Article, "/articles/:id", true),
  stackBuilder(Notifications, "/notifications", true),
  stackBuilder(Payment, "/payment"),
  stackBuilder(Page, "/pages/:slug"),
  stackBuilder(About, "/about"),
  stackBuilder(OBU, "/obu"),
  stackBuilder(OBUPackage, "/obu/our-package"),
  stackBuilder(OBUResources, "/obu/resources"),
  stackBuilder(MasterClasses, "/master_classes"),
  stackBuilder(ShowMasterClass, "/masterclasses/:id"),
  stackBuilder(SendEmail, "/password/email"),
  stackBuilder(UpdatePassword, "/password/update"),
  stackBuilder(OrderUpdate, "/orders/update"),
  ...AdminStack
];


const Routes = ({}) => {

  const currentUser = React.useContext(AppContext);


  return(
    <Switch>
      {
        stacks.map(({ Component, path, auth, roles }, key) =>
          <Route
            exact
            { ...{ key, path } }
            render={ (props) => {
              const user = currentUser.currentUser;
              if(auth && currentUser.isFetching) return null
              if(auth && !user) return <Redirect to={{
                  pathname: "/login",
                  search: `?redirect=${window.location.pathname}`,
                }} />
              if(roles && !roles.includes(user.role)) return <Redirect to="/" />
              return <Component { ...props } />
            } }
          />
        )
      }
    </Switch>
  );
};

export default Routes;
