import React, { useState } from "react";
import {
  Link,
  useHistory,
} from "react-router-dom";
import {AppContext} from "App";

const useCheckout = () => {

  const history = useHistory();


  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog,
    setCart,
    cart: Cart,
  } = React.useContext(AppContext);

  const [state, setState] = useState("Monthly");

  const onCheckout = async () => {
    if(Cart.length === 0) return;
    let cart_id = localStorage.getItem("cart_id");
    try {
      openModal();
      if(cart_id){
        const res = await window.authFetch(`orders?cart_id=${cart_id}`, {
          method: "post",
          body: JSON.stringify({
            subscription_type: state
          })
        });
        if(res.ok){
          const body = await res.json();
          setCart([]);
          const link = body.link;
          localStorage.setItem("order_id", body.order_id);
          window.location.replace(link);
        }else {
          throw new Error();
        }
      }
    } catch (e) {
      console.log(e);
      openErrorDialog();
    }finally{
      closeModal();
    }
  }

  return{
    onCheckout,
    state,
    setState,
  }

}

export default useCheckout;
