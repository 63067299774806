import React from "react";
import Article from "components/Article";

const ShowArticle = ({ sub, completed }) => {

  return(
    <>
      <Article sub={sub} complete={completed} />
    </>
  )
}

export default ShowArticle;
