import React from "react";

const useHome = () => {
  const [home, setHome] = React.useState({
    popularCourses: [],
    topCourses: [],
    categories: []
  });

  const fetchHome = async () => {
    const res = await window.authFetch(`home`);
    if(res.status == 200){
      const body = await res.json();
      setHome(body);
    }
  }

  React.useEffect(() => {
    fetchHome();
  }, []);

  return{
    ...home,
  }
}

export default useHome;
