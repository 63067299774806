import React from "react";

const useOrders = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [orders, setOrders] = React.useState({
    orders: []
  });


  const fetchOrders = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/orders`);
      if(res.status == 200){
        const body = await res.json();
        setOrders(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchOrders();
  }, []);


  return{
    fetchOrders,
    ...orders
  }

}

export default useOrders;
