import React from "react";
import TableRow from "./TableRow";

const Table = (props) => {

  const {
    headers,
    rows: body,
    title,
    resource,
    actions,
    show = true,
    AddOnRow,
  } = props;

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(body);
  }, [body]);

  return (
    <div className="card login-page bg-white shadow rounded border-0">
      <div className="card-body">
        <h4 className="card-title">{title}</h4>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              {
                headers.map((header, i) => (
                  <th scope="col" key={i}>{header.title}</th>
                ))
              }
              {
                actions ?
                  <th scope="col">Actions</th>
                  : null
              }
            </tr>
          </thead>
          <tbody>
            {
              rows.map((row, i) => (
                <>
                  <TableRow
                    row={row}
                    i={i}
                    key={i}
                    {...props}
                    rows={rows}
                    setRows={setRows}
                  />
                  {
                    AddOnRow ?
                      <AddOnRow
                        row={row}
                        i={i}
                        key={i}
                        {...props}
                        rows={rows}
                        setRows={setRows}
                      />
                      : null
                  }
                </>
              ))
            }
          </tbody>
        </table>

      </div>
    </div>
  )
}

export default Table;
