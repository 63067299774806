import CardPage from "components/CardPage";
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from "components/BaseTextField";
import Button from "components/BaseButton";
import { useEffect } from "react";
import useUpdatePassword from "./useUpdatePassword";

const validationSchema = yup.object({
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    password_confirmation: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

const UpdatePassword = () => {

    const {
        onSubmit,
    } = useUpdatePassword();


    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        validationSchema,
        onSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        window.feather.replace();
    }, []);

    return (
        <CardPage
            title="Update Password"
        >
            <form className="login-form mt-4" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <TextField
                        name="password"
                        label="Password"
                        placeholder="Password"
                        type="password"
                        form={formik}
                        Icon={"key"}
                    />

                    <TextField
                        name="password_confirmation"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        type="password"
                        form={formik}
                        Icon={"key"}
                    />

                    <Button>
                        Update Password
                    </Button>
                </div>
            </form>
        </CardPage>
    );
}

export default UpdatePassword;