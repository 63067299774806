import {
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const Aside = () => (
  <section className="section">
      <div className="container">
          <div className="row justify-content-center">
              <div className="col-12">
                  <div className="section-title text-center mb-4 pb-2">
                      <h4 className="title mb-4">Paced Learning. Anytime. Anywhere.</h4>
                      <p className="text-muted para-desc mx-auto mb-0"><Link to="/sign_up" className="text-primary fw-bold">Sign up</Link> to get started on your journey to becoming a world class finance professional.</p>
                  </div>
              </div>
          </div>

          <div className="row">
              <div className="col-lg-4 mt-4 pt-2">
                  <div className="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                      <div className="align-items-center">
                          <span className="h2 mb-0 icon2 text-primary float-md-end">
                              <i className="uil uil-mobile-android"></i>
                          </span>
                          <h5 className="mb-0 mt-md-2">On-demand</h5>
                      </div>
                      <div className="card-body p-0 content mt-3">
                          <p className="para text-muted mb-0">Bite-sized, easy to digest concepts and materials.</p>
                      </div>
                  </div>


              </div>

              <div className="col-lg-4 mt-4 pt-2">
                  <div className="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                      <div className="align-items-center">
                          <span className="h2 mb-0 icon2 text-primary float-md-end">
                              <i className="uil uil-airplay"></i>
                          </span>
                          <h5 className="mb-0 mt-md-2">Word Class</h5>
                      </div>
                      <div className="card-body p-0 content mt-3">
                          <p className="para text-muted mb-0">Choose courses taught by real-world experts</p>
                      </div>
                  </div>


              </div>

              <div className="col-lg-4 mt-4 pt-2">
                  <div className="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0">
                      <div className="align-items-center">
                          <span className="h2 mb-0 icon2 text-primary float-md-end">
                              <i className="uil uil-mobile-android"></i>
                          </span>
                          <h5 className="mb-0 mt-md-2">Accessibility</h5>
                      </div>
                      <div className="card-body p-0 content mt-3">
                          <p className="para text-muted mb-0">Learn at your own pace, with access on mobile and desktop.</p>
                      </div>
                  </div>


              </div>
          </div>
      </div>
  </section>
)

export default Aside;
