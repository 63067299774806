import React from "react";

const useCategories = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [categories, setCategories] = React.useState([]);


  const fetchCategories = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/categories`);
      if(res.status == 200){
        const body = await res.json();
        setCategories(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchCategories();
  }, []);


  return{
    fetchCategories,
    categories,
    setCategories
  }

}

export default useCategories;
