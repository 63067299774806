import React from "react";
import Navbar from "components/admin/Navbar.js";
import TextField from "components/BaseTextField";
import useSectionForm from "hooks/admin/useSectionForm";
import { Formik } from 'formik';
import * as Yup from "yup";


const SectionFormSchema = Yup.object().shape({
  title: Yup.string()
    .required(),
  description: Yup.string()
    .required(),
});

const SectionForm = ({section = {
  title: "",
  description: ""
}, i}) => {

  let id =  section.id ? `section${section.id}` : "createSection" ;

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    isFetching,
  } = useSectionForm(section, i, id);

  if(isFetching) return null; // makes form initialized

  return(
    <>
      <div className="modal fade" id={id} tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isEditing ? "Edit" : "New"} Section</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Formik
               initialValues={section}
               validationSchema={SectionFormSchema}
               onSubmit={onSubmit}
               className="login-form mt-4"
               validateOnChange={false}
               validateOnBlur={false}
             >
              {
                ({ handleSubmit, ...form }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">

                      <TextField
                        label="Title"
                        name="title"
                        form={form}
                        asyncErrors={asyncErrors}
                       />

                      <TextField
                        label="Description"
                        name="description"
                        multiline
                        form={form}
                        asyncErrors={asyncErrors}
                       />

                      <div className="col-lg-12 mb-0">
                        <div className="d-flex">
                          <button type="submit" className={`btn btn-primary ${isSubmitting ? 'disabled' : ''}`}>
                            {isEditing ? "Update Section" : "Add Section"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )
              }
            </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionForm;
