import React from "react";
import {
  Link
} from "react-router-dom";

import CourseUp from "components/CourseUp";
import Button from "components/BaseButton";
import useTest from "hooks/useTest";
import {VideoSidebar} from "views/Video";
import BackButton from "components/BackButton";
import {Prev, Next} from "components/Nav";

const Question = ({ question, onSetAnswer, answers, marks }) => {

  const [correct, setCorrect] = React.useState();

  React.useEffect(() => {
    if(marks){
      let c = marks.results.filter((result, i) => result.question_id == question.id)[0].correct;
      setCorrect(c);
    }
  }, [marks]);

  return(
    <li className="mt-3">
      <h6 className="h6 mb-0">{question.body} </h6><br />
      <ol className="custom-control custom-radio ps-0 list-unstyled">
      {
        question.answers.map((answer, i) => <Answer
          answer={answer}
          question={question}
          onSetAnswer={onSetAnswer}
          answers={answers}
          marks={marks}
          />
        )
      }
      </ol>
      
      {
        marks ?
          correct ? 
            <p><img src="" width="15px" /><h7 class="mt-7 text-success"> Correct </h7></p>  
          : 
            <p><img src="/images/error_icon.png" width="15px" /><h7 class="mt-7 text-danger"> Incorrect </h7></p> 
        : null
      }
    </li>
  )
}

const Answer = ({answer, question, onSetAnswer, answers, marks}) => {

  const onClick = () => onSetAnswer(question.id, answer.id);

  return(
     <li className="mt-2 alert alert-light"  onClick={onClick}>
       <div className="custom-control custom-radio custom-control-inline">
         <div className="mb-0">
           <input
             type="radio"
             name={question.id}
             value={answer.id}
             className="form-check-input flexRadioDefault me-2"
             id="flexRadioDefault1"
             checked={answers[question.id] === answer.id}
           />
          <label className="form-check-label" for="flexRadioDefault1">{answer.body}</label>
         </div>
       </div>
     </li>
  )
}

const Test = ({questions, sub}) => {

  const {
    marks,
    handleToggleDialog,
    onClose,
    openDialog,
    onSubmit,
    answers,
    onSetAnswer,
    onCloseMarks,
  } = useTest(sub);

  React.useEffect(() => {
    var myModal = new window.bootstrap.Modal(document.getElementById('testModal'));
    if(sub.completed){
      myModal.show()
    }
    return () => myModal.hide();
  }, []);

  React.useEffect(() => {
    var myModal = new window.bootstrap.Modal(document.getElementById('marksModal'));
    if(marks){
      myModal.show()
    }
    return () => {
      if(myModal) myModal.hide();
    }
  }, [marks]);

  return(
    <section className="section">
      <div className="vicontainer course-text-wrapper card shadow rounded border-0 overflow-hidden pt-0">
        <VideoSidebar video={sub} />
        <button class="show-content" onClick={window.onCloseTextVideoSidebar}>
          Open Course Content
        </button>
        <div className="vicontainer-text-wrapper">
          <div class="vicontainer-text-wrapper-inner">
            <div class="vicontainer-text-wrapper-inner-prev">
              <Prev
                sections={sub.sections}
                sub_id={sub.id}
                section_id={sub.section_id}
              />
            </div>
            <div class="vicontainer-text-wrapper-inner-content">
              <div className="">
                <h2 className="title mb-0"> {sub.title}  </h2>
                <ol style={{listStyleType: "decimal", padding: 0}}>
                 {
                   questions.map((question, i) => <Question question={question} i={i} onSetAnswer={onSetAnswer} answers={answers} marks={marks} />)
                 }
               </ol>
              </div>
              <div className="mt-4 d-flex">
                <BackButton id={sub.course_id} />
                {
                  !sub.completed ?
                    <button onClick={onSubmit} className="btn btn-primary ms-4">Submit</button>
                  : null
                }
              </div>
            </div>
            <div class="vicontainer-text-wrapper-inner-next">
              <Next
                sections={sub.sections}
                sub_id={sub.id}
                section_id={sub.section_id}
              />
            </div>
            <div className="modal fade" id="testModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Congratulations</h5>
                    <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    You've completed this test
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="marksModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Your Score</h5>
                    <button type="button" className="btn-close" onClick={onCloseMarks} aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    You scored {marks ? marks.marks : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Test;
