import React from "react";
import queryString from "query-string";
import { useHistory } from "react-router";

const OrderUpdate = () => {

    const history = useHistory();

    const confirmPayment = async () => {
        const parsed = queryString.parse(window.location.search);
        const {
            ref
        } = parsed;
        const res = await global.authFetch(`orders/update?ref=${ref}`, {
            method: "put",
        });
        if (res.ok) {
            const body = await res.json();
            const link = body.link;
            localStorage.setItem("order_id", body.order_id);
            window.location.replace(link);
        }
    }

    React.useEffect(() => {
        confirmPayment();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
                <div className="loader"></div>
                <div className="mt-4">
                    <p>Initializing</p>
                </div>
            </div>
        </div>
    )
}


export default OrderUpdate;