import React from "react";
import DeleteSectionForm from "components/admin/DeleteSectionForm";
import {Link} from "react-router-dom";
import { CourseContext } from "views/admin/ShowCourse";

const Sub = ({sub, i, sectionIndex}) => {

  const {
    setCourse,
    course,
    isApproved,
  } = React.useContext(CourseContext);

  let deleteSubId = `delete-subs-${sub.id}`;

  const onComplete = () => {
    course.sections[sectionIndex].subs.splice(i, 1);
    setCourse({ ...course });
  }

  return(
    <div className="d-flex justify-content-between">
      <Link to={`/admin/${sub.attachable_type.toLowerCase()}s/${sub.attachable_id}`} className="form-check" style={{color: "inherit"}}>
        {sub.title}
      </Link>
      {
        !isApproved() ?
        <>
        <button
          data-bs-toggle="modal"
          data-bs-target={`#${deleteSubId}`}
          className="btn btn-icon"
        >
          <i className="uil uil-trash"></i>
        </button>
        <DeleteSectionForm
          id={deleteSubId}
          path={`admin/subs/${sub.id}`}
          title={sub.title}
          onComplete={onComplete}
        />
        </>
        : null
      }
    </div>
  )
}

export default Sub;
