import React from 'react';

import {
  BrowserRouter,
} from 'react-router-dom';

import useCart from "./hooks/useCart";
import useCurrentUser from "./hooks/useCurrentUser";

import Activity from "components/Activity";
import ErrorDialog from "components/Error";

import Routes from "Routes";

export const AppContext = React.createContext();

const App = () => {

  const [errorMessage, setErrorMessage] = React.useState("");

  const openModal = () => {
    var myModal = new window.bootstrap.Modal(document.getElementById('activity'), {backdrop: 'static'});
    myModal.show();
  }

  const openErrorDialog = (message) => {
    setErrorMessage(message);
    var myModal = new window.bootstrap.Modal(document.getElementById('errorDialog'));
    myModal.show();
  }

  const closeModal = () => {
    var myModal = window.bootstrap.Modal.getInstance(document.getElementById('activity'), {backdrop: 'static'});
    myModal.hide();
  }

  const currentUser = useCurrentUser({
    openModal,
    openErrorDialog,
    closeModal,
  });

  const cart = useCart({
    openModal,
    openErrorDialog,
    closeModal,
  });

  const appContext = {
    ...cart,
    ...currentUser,
    openModal,
    closeModal,
    openErrorDialog,
  };


  return(
    <AppContext.Provider value={appContext}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <Activity />
      <ErrorDialog 
        errorMessage={errorMessage}
      />
    </AppContext.Provider>
  );
};

export default App;
