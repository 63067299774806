import React from "react";

const Activity = () => {
  return(
    <div className="modal " id={"activity"} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activity;
