import moment from "moment";
import DP from "react-date-picker";

const DatePicker = ({ label, name, form, helperText }) => {

    let error = Boolean(form.errors[name]);
    if(!helperText) helperText = form.errors[name];

    return (
        <div className="mb-3">
            <label className="form-label">
                {label}
            </label>
            <DP
                name={name}
                className={`form-control  ${error ? 'border-danger' : ''}`}
                value={moment(form.values[name]).toDate()}
                onChange={(value) => form.handleChange({ target: { name, value } })}
            />
            {
                helperText ?
                    <p className={`ms-3 mt-1 ${error ? 'text-danger' : ''}`}>{helperText}</p>
                    : null
            }
        </div>
    )
}

export default DatePicker;