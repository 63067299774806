import React from "react";

const usePages = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [pages, setPages] = React.useState([]);


  const fetchPages = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/pages`);
      if(res.status == 200){
        const body = await res.json();
        setPages(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchPages();
  }, []);


  return{
    fetchPages,
    pages
  }

}

export default usePages;
