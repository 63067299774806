import React from "react";
import {
  useParams
} from "react-router-dom";

const useVideo = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [video, setVideo] = React.useState({
    resources: []
  });

  const {
    id
  } = useParams();

  const fetchVideo = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/videos/${id}`);
      if(res.status == 200){
        const body = await res.json();
        setVideo(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchVideo();
  }, []);


  return{
    fetchVideo,
    video,
    setVideo
  }

}

export default useVideo;
