import { AppContext } from "App";
import useFetch from "lib/useFetch";
import { useContext, useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router";

const useUpdatePassword = () => {

    const history = useHistory();

    const {
        setCurrentUser,
        openModal,
        closeModal,
        openErrorDialog
    } = useContext(AppContext);

    const decoded = window.location.search.replace(/&amp;/g, '&');

    const {
        email,
        token,
    } = queryString.parse(decoded);

    const onSuccess = () => {
        history.push("/login");
    }

    const onError = async (res) => {
        openErrorDialog();
    }
    const onFinish = () => {
        closeModal();
    }

    const {
        onPost,
    } = useFetch("/password/reset", onSuccess, onError, onFinish);

    const onSubmit = (values) => {
        openModal();
        onPost({
            ...values,
            email,
            token
        })
    }

    return {
        onSubmit,
    }
}

export default useUpdatePassword;