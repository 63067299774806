import React from "react";
import {AppContext} from "App";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const useLogin = () => {

  const history = useHistory();

  const [asyncErrors, setAsyncErrors] = React.useState();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const onSubmit = async (body) => {
    try {
      openModal();
      var path = window.location.pathname == "/login" ? '/login' : '/register';
      const res = await fetch(`${window.host}${path}`, {
        method: "post",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(body)
      });
      console.log(res.status);
      if(res.ok) {
        var token = res.headers.get("Authorization");
        token = token.substring(7, token.length);
        localStorage.setItem("token", token);
        const body = await res.json();
        setCurrentUser(body);
        const {redirect} = queryString.parse(window.location.search);
        let path = "/";
        if(redirect) path = redirect;
        history.push(path);
      }else if (res.status == 401) {
        openErrorDialog("Invalid Email or Password");
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  return{
    onSubmit,
    asyncErrors
  }
}

export default useLogin;
