import React from "react";
import {
  Link
} from "react-router-dom";
import { AppContext } from "App";

const LinkButton = ({link}) => {
  const {
    icon,
    name,
    ...others
  } = link;
  return <li className="navbar-item account-menu px-0">
    <Link {...others} className="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
      <span className="h4 mb-0">
        <i className={`uil uil-${icon}`}></i>
      </span>
      <h6 className="mb-0 ms-2">{name}</h6>
    </Link>
  </li>
}

const UserLinks = () => {

  const {
    currentUser = { },
    signOut,
  } = React.useContext(AppContext);

  return(
    <div className="col-lg-4 col-md-6 col-12 d-lg-block d-none">
        <div className="sidebar sticky-bar p-4 rounded shadow">

            <div className="widget mt-4">
                <ul className="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                  {
                    [
                      {
                        name: "Profile",
                        icon: "dashboard",
                        to: "/account"
                      },
                      /*{
                        name: "Messages",
                        icon: "envelope-star",
                        to: "/messages"
                      },
                      */
                      {
                        name: "Notifications",
                        icon: "bell",
                        to: "/notifications"
                      },
                      {
                        icon: "dashboard",
                        name: "Logout",
                        onClick: (e) => {
                          e.preventDefault();
                          signOut();
                        }
                      }
                    ].map((link, i) => <LinkButton key={i} link={link} />)
                  }
                </ul>
            </div>


        </div>
    </div>
  )
}

export default UserLinks;
