import React from "react";
import Navbar from "components/admin/Navbar.js";
import TextField from "components/BaseTextField";
import useTaxForm from "./useTaxForm";
import { Formik } from 'formik';
import * as Yup from "yup";
import BackButton from "components/admin/BackButton";


const TaxFormSchema = Yup.object().shape({
  name: Yup.string()
    .required(),
});

const TaxForm = () => {

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    tax,
    isFetching,
  } = useTaxForm();

  if (isFetching) return null;

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card login-tax bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-name">{isEditing ? "Edit Tax" : "New Tax"}</h4>
                <Formik
                  initialValues={tax}
                  validationSchema={TaxFormSchema}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {
                    ({ handleSubmit, ...form }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">

                          <TextField
                            label="Name"
                            name="name"
                            form={form}
                            asyncErrors={asyncErrors}
                          />

                          <TextField
                            label="Percent"
                            name="percent"
                            form={form}
                            asyncErrors={asyncErrors}
                          />

                          <TextField
                            label="Apply"
                            name="apply"
                            form={form}
                            asyncErrors={asyncErrors}
                          />

                          <div className="col-lg-12 mb-0">
                            <div className="d-flex">
                              <button type="submit" className={`btn btn-primary ${isSubmitting ? 'disabled' : ''}`}>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }
                </Formik>
              </div>
            </div>
            <BackButton />
          </div>
        </div>
      </div>
    </>
  )
}

export default TaxForm;
