import React from "react";

const useCurrentUser = ({
  openModal,
  openErrorDialog,
  closeModal,
}) => {

  const [currentUser, setCurrentUser] = React.useState();
  const [isFetching, setIsFetching] = React.useState(true);

  const doValidate = async () => {
    try {
      setIsFetching(true);
      const res = await window.authFetch(`user`);
      if(res.status == 200){
        const body = await res.json();
        setCurrentUser(body);
      }
    } catch (e) {
      console.log(e);
    }finally{
      setIsFetching(false);
    }
  };

  const deleteUser = async () => {
    try {
      openModal();
      const res = await window.authFetch(`user`, {
        method: "delete"
      });
      if(res.status == 200){
        const body = await res.json();
        signOut();
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    }finally{
      closeModal();
    }
  };

  const signOut = () => {
    localStorage.removeItem("token", "");
    window.location = "/";
  }

  React.useEffect(() => {
    let token = localStorage.getItem("token");
    if(token) doValidate();
    else setIsFetching(false);
  }, []);

  const isAdmin = () => {
    if(currentUser) return currentUser.role.includes("Super Admin");
  }


  const isTutor = () => {
    if(currentUser) return isAdmin() || currentUser.role.includes("Tutor");
  }

  return{
    currentUser,
    signOut,
    setCurrentUser,
    isFetching,
    deleteUser,
    isAdmin,
    isTutor
  }

}

export default useCurrentUser;
