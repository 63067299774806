import React, { useRef } from "react";
import TextField from "components/BaseTextField";
import useNewVideo from "hooks/admin/useNewVideo";
import { Formik } from 'formik';
import * as Yup from "yup";
import BackButton from "components/admin/BackButton";
import ChunkedUploady from "@rpldy/chunked-uploady";
import { host } from "index";
import UploadButtonC from "./UploadButtonC";
import ActivityPage from "components/ActivityPage";

const VideoSchhema = Yup.object().shape({
  title: Yup.string()
    .required(),
  description: Yup.string()
    .required(),
});


const NewVideo = () => {

  const [src, setSrc] = React.useState();

  const {
    asyncErrors,
    onSubmit,
    video,
    isEditing,
  } = useNewVideo(src);

  if (isEditing && !video.id) return (
    <ActivityPage />
  );

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-title">{isEditing ? "Edit Video" : "New Video"}</h4>
                <Formik
                  initialValues={video}
                  validationSchema={VideoSchhema}
                  onSubmit={onSubmit}
                  className="login-form mt-4"
                >
                  {
                    ({ handleSubmit, ...form }) => (
                      <div className="row">

                        {
                          src ?
                            <video
                              id="video"
                              src={src}
                              controls
                            />
                            : null
                        }
                        <ChunkedUploady
                          method="POST"
                          destination={{ url: `${host}/upload`, headers: { "x-custom": "123" } }}
                          chunkSize={1024 * 1024 * 100}
                          inputFieldName={'file'}
                        >
                          <UploadButtonC
                            setSrc={setSrc}
                          />
                        </ChunkedUploady>

                        <form onSubmit={handleSubmit}>

                          <TextField
                            label="Title"
                            name="title"
                            form={form}
                            asyncErrors={asyncErrors}
                          />

                          <TextField
                            label="Description"
                            name="description"
                            form={form}
                            asyncErrors={asyncErrors}
                            multiline
                          />

                          <div className="col-lg-12 mb-0">
                            <div className="d-grid">
                              <button type="submit" className="btn btn-primary">Upload</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )
                  }
                </Formik>
              </div>
            </div>
            <BackButton />

          </div>
        </div>
      </div>
    </>
  )
}

export default NewVideo;
