import React from "react";
import {
  Link
} from "react-router-dom";
import { AppContext } from "App";
import Navbar from "components/Navbar";
import UserShort from "components/UserShort";
import UserLinks from "components/UserLinks";
import DeleteAccount from "components/DeleteAccount";
import ChangePassword from "components/ChangePassword";
import PersonalDetails from "components/PersonalDetails";

const Account = () => {

  const {
    currentUser = { },
    signOut,
  } = React.useContext(AppContext);


  return(
    <>
      <Navbar />
      <UserShort
        currentUser={currentUser}
      />
      <section className="section mt-60">
        <div className="container mt-lg-3">
          <div className="row">
            <UserLinks />
            <div className="col-lg-8 col-12">
              <div className="card border-0 rounded shadow">
                <div className="card-body">
                  <PersonalDetails />
                  <ChangePassword />
                </div>
              </div>
              <DeleteAccount />
            </div>
          </div>
        </div>
       </section>
     </>
  )
}

export default Account;
