import React from "react";
import queryString from "query-string";

const useMasterClasses = () => {

  const [search, setSearch] = React.useState("");
  const [category, setCategory] = React.useState("");

  const onChange = ({ target }) => setSearch(target.value);

  const onChangeCategory = ({ target }) => setCategory(target.value);

  const onSubmit = (e) => {
    e.preventDefault();
    fetchMasterClasses();
  }

  const [masterClasses, setMasterClasses] = React.useState({
    masterClasses: {
      data: [],
      meta: {
        pagination: {

        }
      }
    },
    categories: []
  });

  const fetchMasterClasses = async () => {
    const res = await window.authFetch(`master_classes?filter[search]=${search}&filter[category]=${category}`);
    if (res.status == 200) {
      const body = await res.json();
      setMasterClasses(body);
    }
  }

  React.useEffect(() => {
    if (masterClasses.categories.length > 0) {
      const {
        category
      } = queryString.parse(window.location.search);
      if (category) {
        let cat = masterClasses.categories.filter((cat) => cat.name.toLowerCase() == category.toLocaleLowerCase());
        setCategory(cat[0].name);
      }
    }
  }, [masterClasses.categories.length]);

  React.useEffect(() => {
    fetchMasterClasses();
  }, [category]);

  return {
    masterClasses,
    search,
    onChange,
    onSubmit,
    onChangeCategory,
    category
  }
}

export default useMasterClasses;
