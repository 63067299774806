import React from "react";
import { useHistory, useParams } from "react-router-dom";
import toBase64 from "lib/toBase64";
import {AppContext} from "App";

const useCourseForm = () => {

  const history = useHistory();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [crop, setCrop] = React.useState({ 
    aspect: 16 / 9,
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const [src, setSrc] = React.useState();

  const [image, setImage] = React.useState();

  const [isSubmitting, setIsSubmitting] = React.useState();
  const [isFetching, setIsFetching] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState();

  const [course, setCourse] = React.useState({
    title: "",
    description: "",
    price: "",
    duration: "",
    code: ""
  });
  const [categories, setCategories] = React.useState([]);
  const [tutors, setTutors] = React.useState([]);

  const [asyncErrors, setAsyncErrors] = React.useState({});

  const { id } = useParams();

  const newCourse = async () => {
    try {
      let url = "admin/courses/create";
      const res = await window.authFetch(url);
      if(res.status == 200){
        const body = await res.json();
        setCategories(body.categories);
        setTutors(body.tutors);
        if(isEditing) setCourse(body.course);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }

  // detecting isEditing through route was lagging
  const editCourse = async () => {
    try {
      let url = `admin/courses/${id}/edit`;
      const res = await window.authFetch(url);
      if(res.status == 200){
        const body = await res.json();
        setCategories(body.categories);
        setTutors(body.tutors);
        setCourse(body.course);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }

  React.useEffect(() => {
    if(isEditing) editCourse();
  }, [isEditing]);

  React.useEffect(() => {
    const path = window.location.pathname;
    if(path.includes("edit")){
      setIsEditing(true);
    }else newCourse();
  }, []);

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      openModal();
      const file = toBase64(image, crop);
      let url = isEditing ? `admin/courses/${id}` : `admin/courses`;
      const res = await window.authFetch(url, {
        method: isEditing ? "put" : "post",
        body: JSON.stringify({
          ...values,
          file
        })
      });
      if(res.ok){
        const body = await res.json();
        history.push(`/admin/courses/${body.id}`);
      }else if (res.status == 422) {
        const body = await res.json();
        setAsyncErrors({...body});
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      setIsSubmitting(false);
      closeModal();
    }
  }


  return{
    asyncErrors,
    onSubmit,
    isSubmitting,
    categories,
    tutors,
    isEditing,
    crop,
    setCrop,
    image,
    setImage,
    src,
    setSrc,
    course,
    isFetching
  }

}

export default useCourseForm;
