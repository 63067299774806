import React from "react";
import useHome from "hooks/admin/useHome";
import Navbar from "components/admin/Navbar";
import { Link } from "react-router-dom";
import Table from "components/admin/Table";
import InfoBox from "components/admin/InfoBox";
import {AppContext} from "App";

const Info = ({
  studentsCount,
  income,
  coursesCount,
}) => {

  const {
    isAdmin
  } = React.useContext(AppContext);

  return(
    <div className="row">
      {
        isAdmin() ?
        <>
        <div className="col-lg-3">
          <InfoBox
            title="Students"
            count={studentsCount}
            icon={"uil uil-users-alt"}
            backgroundColor="#f17425"
          />
        </div>
        <div className="col-lg-3">
          <InfoBox
            title="Income"
            count={`$${income}`}
            icon={"uil uil-money-stack"}
            backgroundColor="green"
          />
        </div>
        </>
        : null
      }
      <div className="col-lg-3">
        <InfoBox
          title="Courses"
          count={coursesCount}
          icon={"uil uil-graduation-cap"}
          backgroundColor="#03254c"
        />
      </div>
    </div>
  )
}

const Home = () => {

  const home = useHome();

  const {
    isAdmin
  } = React.useContext(AppContext);

  const {
    studentsCount,
    coursesCount,
    recentOrders,
    recentStudents,
    recentCourses,
    income,
  } = home;

  return (
    <>
      <section style={{backgroundColor: "#f7f7f7"}} className="vh-100">
        <div className="container pt-5">
          <Info {...home} />
          <div className="row">
            {
              isAdmin() ?
              <>
              <div className="col-lg-6">
                <Table
                  headers={[
                    {title: "User"},
                    {title: "Total"},
                  ]}
                  rows={recentOrders}
                  title="Recent Orders"
                />
              </div>
              <div className="col-lg-6">
                <Table
                  headers={[
                    {title: "Name"},
                  ]}
                  rows={recentStudents}
                  title="Recent Students"
                />
              </div>
              </>
              : null
            }
            <div className="col-lg-6 mt-4">
              <Table
                headers={[
                  {title: "Title"},
                  {title: "State"},
                  {title: "Tutor"},
                  {title: "Enrolled"},
                ]}
                rows={recentCourses}
                title="Top Courses"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
