import React from "react";

import useMasterClass from "./useMasterClass"
import { AppContext } from "App";

import MasterClassUp from "components/CourseUp";
import MasterClassInformation from "components/CourseInformation";
import Navbar from "components/Navbar";

import {
  Link
} from "react-router-dom";
import Editor from "components/admin/Editor";

const ShowMasterClass = () => {

  const {
    addToCart,
    canAddToCart,
  } = React.useContext(AppContext);

  const {
    masterClass,
  } = useMasterClass();

  if (!masterClass) return null;

  const {
    title,
  } = masterClass;

  const onAddToCart = () => addToCart(masterClass, "masterclass");

  return (
    <>
      <Navbar />
      <MasterClassUp title={title} />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-12">
              <MasterClassInformation course={{ ...masterClass, duration: "LIVE"}} />
              <div className="mt-4">
                {
                  canAddToCart(masterClass) ?
                    <button 
                      onClick={onAddToCart} 
                      className="btn btn-outline-primary">
                        Add to cart <i className="mdi mdi-send"></i>
                    </button>
                    : null
                }
              </div>
            </div>

            <div className="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="ms-lg-4">
                <Editor
                  body={masterClass.more}
                  readOnly
                  toolbarHidden
                  isEditing={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ShowMasterClass;
