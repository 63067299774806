import React from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom";
import {AppContext} from "App";

const useTaxForm = () => {

  const history = useHistory();

  const { id } = useParams();

  const [isSubmitting, setIsSubmitting] = React.useState();
  const [isFetching, setIsFetching] = React.useState();
  const [isEditing, setIsEditing] = React.useState();

  const [tax, setTax] = React.useState({
    name: "",
    percent: "",
    apply: 0,
  });

  const {
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [asyncErrors, setAsyncErrors] = React.useState({});

  const fetchTax = async () => {
    try {
      setIsFetching(true);
      let url = `admin/taxes/${id}/edit`;
      const res = await window.authFetch(url);
      if(res.status == 200){
        const body = await res.json();
        setTax(body);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }



  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      openModal();
      let url = isEditing ? `admin/taxes/${id}` : `admin/taxes`;
      let method = isEditing ? "put" : "post";
      const res = await window.authFetch(url, {
        method,
        body: JSON.stringify({
          ...values,
        })
      });
      if(res.ok){
        //const body = await res.json();
        history.push(`/admin/taxes`);
      }else if (res.status == 422) {
        const body = await res.json();
        setAsyncErrors({...body});
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      setIsSubmitting(false);
      closeModal();
    }
  }

  React.useEffect(() => {
    const path = window.location.pathname;
    if(path.includes("edit")){
      setIsEditing(true);
      fetchTax();
    }
  }, []);


  return{
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    tax,
    isFetching,
  }

}

export default useTaxForm;
