import React from "react";

const useHome = () => {

  const [home, setHome] = React.useState({
    income: 0,
    courses: [],
    students: [],
    orders: [],
    recentCourses: [],
    recentStudents: [],
    recentOrders: []
  });

  const fetchHome = async (values) => {

    try {
      const res = await window.authFetch(`admin/home`);
      if(res.status == 200){
        const body = await res.json();
        setHome({...home, ...body});
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchHome();
  }, []);

  return {
    ...home
  }

}

export default useHome;
