import React from "react";
import {Link} from "react-router-dom";

export const Prev = ({sections, sub_id, section_id}) => {

  const activeSection = sections.filter((section) => section.id == section_id)[0];
  const sub = activeSection.subs.filter((sub) => sub.id == sub_id)[0];
  const index = activeSection.subs.indexOf(sub);
  let prev;
  let next;

  if(index != 0) prev = activeSection.subs[index - 1];

  return(
    <>
      {
        prev ?
        <a href={`/admin/${prev.attachable_type.toLowerCase()}s/${prev.id}`} className="">
          <i class="uil uil-angle-left-b"></i>
        </a>
        : null
      }
    </>
  )
}

export const Next = ({sections, sub_id, section_id}) => {

  const activeSection = sections.filter((section) => section.id == section_id)[0];
  const sub = activeSection.subs.filter((sub) => sub.id == sub_id)[0];
  const index = activeSection.subs.indexOf(sub);
  let prev;
  let next;

  if(index + 1 != activeSection.subs.length) next = activeSection.subs[index + 1];

  return(
    <>
      {
        next ?

        <a href={`/admin/${next.attachable_type.toLowerCase()}s/${next.id}`} className="">
          <i class="uil uil-angle-right-b"></i>
        </a>
        : null
      }
    </>
  )
}
