import React from "react";

const useTutors = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [tutors, setTutors] = React.useState([]);


  const fetchTutors = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/tutors`);
      if(res.status == 200){
        const body = await res.json();
        setTutors(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchTutors();
  }, []);


  return{
    fetchTutors,
    tutors
  }

}

export default useTutors;
