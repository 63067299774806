import { host } from "index";
import React from "react";;

const useFetch = (path, onSuccess, onError = () => {}, onFinish = () => {}) => {

    const [isFetching, setIsFetching] = React.useState(false);

    const onFetch = async (method = 'get', body = undefined) => {
        const token = localStorage.getItem("token");
        const _path = path.endsWith('/') ? path.substr(0, path.length - 1) : path;
        try {
            setIsFetching(true);
            const res = await fetch(`${host}${_path}`, {
                method,
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token},`
                },
                body: JSON.stringify(body)
            });
            console.log(`${_path}:${res.status}`);
            if (res.ok) {
                const body = await res.json();
                onSuccess(body);
            }else{
                onError(res);
            }
        } catch (e) {
            console.log(e);
            
        }finally{
            setIsFetching(false)
            onFinish();
        }
    }

    const onPost = async (body) => onFetch("post", body)

    const onPut = async (body) => onFetch("put", body)

    const onDelete = async () => onFetch("delete")

    return {
        onFetch,
        isFetching,
        onPost,
        onPut,
        onDelete
    }

}

export default useFetch;