import React from "react";
import useCategories from "hooks/admin/useCategories";
import Navbar from "components/admin/Navbar";
import {Link} from "react-router-dom";
import Table from "components/admin/Table";
import TextField from "components/BaseTextField";

const Categories = () => {

  const {
    categories,
    setCategories
  } = useCategories();


  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2>Categories</h2>
          <div>
            <Link to="/admin/categories/new" className="btn btn-primary login-btn-primary">New Category</Link>
          </div>
        </div>
        <Table
          headers={[
            {title: "Name"}
          ]}
          rows={categories}
          resource="categories"
          actions={true}
          show={false}
        />
      </div>

    </>
  );
}

export default Categories;
