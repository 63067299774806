const handleSetImage = ({target, setSrc}) => {
  if (target.files && target.files[0]) {
    var reader = new FileReader();

    reader.onload = function ({target}) {
      setSrc(target.result);
    }

    reader.readAsDataURL(target.files[0]);
  }
}

export default handleSetImage;
