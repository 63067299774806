import React from "react";
import {
  useHistory
} from "react-router-dom";

const BackButton = () => {

  const history = useHistory();

  return <div onClick={history.goBack} className="btn btn-outline-primary">Back</div>

}

export default BackButton;
