import { AppContext } from "App";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Currentuser = () => {

    const {
        currentUser,
        signOut
      } = useContext(AppContext);

    return (

        <div>
            <div className="dropdown d-inline-block">
                <button className="btn btn-icon dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img role="avatar" src={currentUser.avatar} style={{ borderRadius: "50%", width: "35px" }} />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <Link to="/account" className="dropdown-item">Account</Link>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <button onClick={signOut} className="dropdown-item">Logout</button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Currentuser;