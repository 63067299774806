import React from "react";

const useNotifications = () => {

  const [notifications, setNotifications] = React.useState([]);

  const fetchOrders = async () => {
    const res = await window.authFetch(`notifications`);
    if(res.status == 200){
      const body = await res.json();
      setNotifications(body);
    }
  }

  React.useEffect(() => {
    fetchOrders();
  }, []);

  return{
    notifications
  }
}

export default useNotifications;
