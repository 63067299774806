import React from "react";
import useOrders from "hooks/admin/useOrders";
import Navbar from "components/admin/Navbar";
import {Link} from "react-router-dom";
import Table from "components/admin/Table";

const Orders = () => {

  const {
    orders,
    export_url,
  } = useOrders();

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2>Orders</h2>
          <div>
            <a href={export_url} className="btn btn-primary login-btn-primary">Export</a>
          </div>
        </div>
        <Table
          headers={[
            {title: "User"},
            {title: "Total"},
            {title: "Date"},
          ]}
          rows={orders}
        />
      </div>
    </>
  );
}

export default Orders;
