import React from 'react';
import Editable from "components/admin/Editable";
import BackButton from "components/admin/BackButton";

const CourseInformation = ({course}) => {

  React.useEffect(() => {
    window.feather.replace();
  }, []);

  return (
    <div className="card sidebar sticky-bar rounded shadow border-0">
        <div className="card-body widget border-bottom">
            <h5 className="mb-0">Course Information</h5>
        </div>

        <div className="card-body">

            <div className="d-flex widget align-items-center mt-3">
                <i data-feather="monitor" className="fea icon-ex-md me-3"></i>
                <div className="flex-1">
                    <h6 className="widget-title mb-1">Category:</h6>
                    <Editable
                      Componet="p"
                      placeholder="category_id"
                      ComponetProps={{className: "text-primary mb-0"}}
                      text={course.category}
                      select
                      options={
                        course.categories
                      }
                    />
                </div>
            </div>



            <div className="d-flex widget align-items-center mt-3">
              <span className="fea icon-ex-md me-3">$</span>
                <div className="flex-1">
                    <h6 className="widget-title mb-1">Price:</h6>
                    <Editable placeholder="Price" Componet="p" ComponetProps={{className: "text-primary mb-0"}} text={course.price} />
                </div>
            </div>

            <div className="d-flex widget align-items-center mt-3">
                <i data-feather="clock" className="fea icon-ex-md me-3"></i>
                <div className="flex-1">
                    <h6 className="widget-title mb-1">Duration</h6>
                    <Editable placeholder="duration" Componet="p" ComponetProps={{className: "text-primary mb-0"}} text={course.duration} />
                </div>
            </div>
            <div className="mt-4">
              <BackButton />
            </div>
        </div>
    </div>
  )
}

export default CourseInformation;
