import React from "react";
import { AppContext } from "App";
import Rating from "components/Rating";
import {Link, useHistory} from "react-router-dom";
import useCourses from "hooks/useCourses";
import Navbar from "components/Navbar";
import CourseUp from "components/CourseUp";

export const Course = ({course, index}) => {

  const {
    addToCart,
    canAddToCart,
    removeItem
  } = React.useContext(AppContext);

  const history = useHistory();

  const onAddToCart = () => addToCart(course);
  const onRemoveItem = () => removeItem(course, index);


  return(
      <div className="col-lg-6 col-12 mb-4 pb-2">
        <Link to={`/courses/${course.id}`}>
        <div className="card job-box rounded shadow border-0 overflow-hidden">
          <div className="border-bottom">
            <div className="position-relative">
              <img src={course.image} className="img-fluid" alt={course.title} />
              <div className="job-overlay bg-white"></div>
            </div>
            <div className="mb-0 position"><a href="course_details.html" className="text-dark h5">{course.category}</a>
              <Rating ratings={course.ratings} />
            </div>
            <ul className="list-unstyled head mb-0">
             <li className="badge bg-warning rounded-pill">{`$${course.price}`}</li>
            </ul>
          </div>

          <div className="card-body content position-relative">

            <div className="company-detail text-center mt-3">
              <h5 className="mb-0">
                {course.title}
              </h5>
              <p className="text-muted">{course.tutor}</p>
            </div>
            {
              history.location.pathname.includes("my_courses") ?
              null
              :

              <div className="d-grid">
                {
                  canAddToCart(course) ?
                    <button onClick={onAddToCart} className="btn btn-outline-primary">Add to Cart</button>
                  : (
                      history.location.pathname.includes("cart") ?
                        <button onClick={onRemoveItem} className="btn btn-outline-primary">Remove from Cart</button>
                      : null
                    )
                }
              </div>
            }
          </div>
        </div>
      </Link>
      </div>
  )
}

const Courses = () => {

  const {
    courses,
    onChange,
    search,
    onSubmit,
    onChangeCategory,
    category,
    categories
  } = useCourses();


  return(
    <>
      <Navbar />
      <CourseUp title="Courses" />
      <section className="section" >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
                <div className="sidebar sticky-bar p-4 rounded shadow">
                    <div className="widget">
                      <form role="search" onSubmit={onSubmit}>
                        <div className="input-group mb-3 border rounded">
                          <input
                            onChange={onChange}
                            value={search}
                            type="text"
                            className="form-control border-0"
                            placeholder="Search Keywords..."
                          />
                          <button type="submit" className="input-group-text bg-transparent border-0" id="searchsubmit">
                            <i className="uil uil-search"></i>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="widget mb-4 pb-4 border-bottom">
                      <h5 className="widget-title">Categories</h5>
                      <div className="mt-4 mb-0">
                        <select onChange={onChangeCategory} value={category} className="form-select form-control" aria-label="Default select example">
                          <option value="">All Topics</option>
                          {
                            courses.categories.map((category, i) => <option key={i}>{category.name}</option>)
                          }

                        </select>
                      </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-8 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0" >
              <div className="row">
                {
                  courses.courses.data.map((course, i) => <Course course={course} index={i} /> )
                }
              </div>
              {
                courses.courses.meta.pagination.total_pages > 1 ?
                <div className="row">
                  <div className="col-12">
                    <ul className="pagination justify-content-center mb-0">
                      <li className={`page-item ${courses.meta.pagination.current_page == 1 ? 'disabled' : ''}`}><a className="page-link" aria-label="Previous">Prev</a></li>
                      <li className="page-item active"><a className="page-link" href="javascript:void(0)">1</a></li>
                      <li className="page-item"><a className="page-link" href="javascript:void(0)">2</a></li>
                      <li className="page-item"><a className="page-link" href="javascript:void(0)">3</a></li>
                      <li className="page-item"><a className="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                    </ul>
                  </div>
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Courses;
