import React from "react";

const useTaxes = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [taxes, setTaxes] = React.useState([]);


  const fetchTaxes = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/taxes`);
      if(res.status == 200){
        const body = await res.json();
        setTaxes(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchTaxes();
  }, []);


  return{
    fetchTaxes,
    taxes
  }

}

export default useTaxes;
