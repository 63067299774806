import React from "react";
import {useHistory} from "react-router-dom";
import {AppContext} from "App";

const useTest = (sub) => {

  const history = useHistory();

  const {
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [marks, setMarks] = React.useState();
  const [answers, setAnswers] = React.useState({});

  const onSetAnswer = (question_id, answer_id) => {
    answers[question_id] = answer_id;
    setAnswers({ ...answers });
  }

  const onSubmit = async () => {
    try {
      openModal();
      const res = await window.authFetch(`tests/${sub.attachable_id}/submit`, {
        method: "post",
        body: JSON.stringify(answers)
      });
      if(res.status == 200){
        const body = await res.json();
        setMarks(body);
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  const onClose = () => history.push(`/courses/${sub.course_id}`)

  const onCloseMarks = () => {
    var myModal = window.bootstrap.Modal.getInstance(document.getElementById("marksModal"));
    myModal.hide();
  }

  return{
    marks,
    onSubmit,
    answers,
    onSetAnswer,
    onClose,
    onCloseMarks,
  }
}

export default useTest;
