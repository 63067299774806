import React from "react";
import { useHistory } from "react-router-dom";
import {AppContext} from "App";

const useQuestionForm = ({test, setTest}, id, q, i) => {

  const history = useHistory();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [answerError, setAnswerError] = React.useState();
  const [questionError, setQuestionError] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  const [question, setQuestion] = React.useState({
    answers: []
  });
  const qRef = React.createRef();
  const aRef = React.createRef();

  const buildQuestionForm = (q) => {
    let ansBodies = q.answers.map((ans) => ans.body);
    // if the first isn't the answer map fails so forEach
    let selectedAnswer
    q.answers.forEach((ans, i) => {
      if(ans.id == q.answer_id){
        selectedAnswer = i;
      }
    });
    setQuestion({ id: q.id, answers: ansBodies, answer: selectedAnswer });
    qRef.current.value = q.body;
  }

  React.useEffect(() => {
    if(!id.includes("create")){
      setIsEditing(true);
      buildQuestionForm(q);
    }
  }, []);


  const onAddAnswer = () => {
    const a = aRef.current.value;
    if(!a) return setAnswerError("Can't add blank answer");
    const sameAnswer = question.answers.filter((ans) => ans == a);
    if(sameAnswer.length > 0) return setAnswerError("Can't repeat answer");
    question.answers.push(a);
    setQuestion({...question});
    aRef.current.value = "";
    setAnswerError("");
  }

  const onCreateQuestion = async () => {
    const q = qRef.current.value;
    if(!q) return setQuestionError("Question can't be blank");
    if(question.answers.length == 0) return setQuestionError("Answers can't be blank");
    if(question.answers.length == 1) return setQuestionError("There must be at least 2 answers");
    if(question.answer == undefined) return setQuestionError("Select a correct answer");

    try {
      openModal();
      let req = isEditing ? { url: `admin/questions/${question.id}`, method: "put" } : { url: `admin/tests/${test.id}/questions`, method: "post" };
      const res = await window.authFetch(req.url, {
        method: req.method,
        body: JSON.stringify({
          body: q,
          answers: question.answers,
          answer: question.answer
        })
      });
      if(res.ok){
        const body = await res.json();
        if(isEditing){
          test.questions[i] = body;
        }else{
          test.questions.push(body);
        }
        setTest({...test});
        setQuestionError("");
        if(isEditing){
          buildQuestionForm(body);
        }else{
          setQuestion({
            answers: []
          });
        }
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
      var myModal = window.bootstrap.Modal.getInstance(document.getElementById(id));
      myModal.hide();
    }
  }

  const handleDelete = (i) => {
    question.answers.splice(i, 1);
    setQuestion({ ...question });
  }

  React.useEffect(() => {
    var myModal = new window.bootstrap.Modal(document.getElementById(id));

    return () => {
      if(myModal) myModal.hide();
    }
  }, []);

  const onSetAnswer = (i) => {
    question.answer = i;
    setQuestion({...question});
  }

  return{
    qRef,
    aRef,
    onAddAnswer,
    onCreateQuestion,
    onSetAnswer,
    question,
    setQuestion,
    answerError,
    questionError,
    isEditing,
    handleDelete
  }

}

export default useQuestionForm;
