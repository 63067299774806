import React from "react";
import { AppContext } from "App";
import { Course } from "views/Courses";
import TextField from "components/RawTextField";
import Button from "components/BaseButton";
import Navbar from "components/Navbar";
import {Link} from "react-router-dom";
import { useFormik } from 'formik';
import useCheckout from "hooks/useCheckout";
import * as yup from 'yup';

const validationSchema = yup.object({
  card_number: yup
    .string()
    .required(),
  cvc: yup
    .string()
    .max(3)
    .required(),
  expiry: yup
    .string()
    .required(),
});

const Cart = () => {

  const {
    cart,
    count,
    total,
    removeItem,
    currentUser
  } = React.useContext(AppContext);

  const {
    checkout: onSubmit,
    asyncErrors
  } = useCheckout();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return(
    <>
      <Navbar />
      <section className="bg-profile d-table w-100 bg-primary" style={{"background": "url('images/account/bg.png') center center"}}>
       <div className="container">
         <div className="row">
           <div className="col-lg-12">
             <div className="card public-profile border-0 rounded shadow" style={{"zIndex": 1}}>
               <div className="card-body">
                 <div className="row align-items-center">

                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
      </section>
      <section className="section" >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <TextField
                  label="Card Number"
                  name="card_number"
                  form={formik}
                  asyncErrors={asyncErrors}
                 />
                 <div className="col-lg-6">
                   <TextField
                     label="Expiry"
                     name="expiry"
                     form={formik}
                     asyncErrors={asyncErrors}
                    />
                 </div>
                 <div className="col-lg-6">
                   <TextField
                     label="CVC"
                     name="cvc"
                     form={formik}
                     asyncErrors={asyncErrors}
                    />
                 </div>
                 <Button onClick={formik.handleSubmit}>Checkout</Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Cart;
