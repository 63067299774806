import React from "react";
import Button from "components/BaseButton";
import TextField from "components/BaseTextField";
import { AppContext } from "App";
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePersonalDetails from "hooks/usePersonalDetails";
import handleSetImage from "lib/handleSetImage";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .required('Name is required'),
});

const PersonalDetails = () => {

  const {
    currentUser = { },
    signOut,
  } = React.useContext(AppContext);

  const [crop, setCrop] = React.useState({ aspect: 1 });

  const [src, setSrc] = React.useState();

  const [image, setImage] = React.useState();

  const {
    onSubmit,
  } = usePersonalDetails(image, crop);

  const onSetImage = ({target}) => handleSetImage({target, setSrc});

  const formik = useFormik({
    initialValues: {
      name: currentUser.name,
    },
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return(
    <>
      <h5 className="text-md-start text-center">Personal Detail :</h5>

      <div className="mt-3 text-md-start text-center">
        <ReactCrop
          src={src}
          crop={crop}
          onChange={setCrop}
          onImageLoaded={setImage}
        />

        <div className="mt-md-4 mt-3 mt-sm-0">
          <div style={{position: "relative"}}>
            <button className="btn btn-outline-primary mt-2">Change Picture</button>
            <input type="file" onChange={onSetImage} name="image" style={{position: "absolute", top: 0, left: 0 , bottom: 0, right: 0, opacity: 0}} />
          </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-4">
          <TextField
            label="Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            helperText={formik.errors.name}
           />
        </div>
        <div className="row">
          <div className="col-sm-12">
            <input type="submit" id="submit" name="send" className="btn btn-primary" value="Save Changes" />
          </div>
        </div>
      </form>
    </>
  )
}

export default PersonalDetails;
