import React from "react";
import {AppContext} from "App";

const useChangePassword = () => {

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const onSubmit = async (values) => {
    try {
      openModal();
      const res = await window.authFetch(`user/password`, {
        method: "put",
        body: JSON.stringify({
          ...values
        })
      });
      if(res.status == 200){
        const body = await res.json();
      }else throw new Error();
    } catch (e) {
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  return{
    onSubmit
  }
}

export default useChangePassword;
