import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {AppContext} from "App";

const useSub = () => {

  const {
    id
  } = useParams();

  const history = useHistory();

  const [sub, setSub] = React.useState();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const fetchSub = async () => {
    let token = localStorage.getItem("token");
    const res = await fetch(`${window.host}/subs/${id}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
    if(res.status == 200){
      const body = await res.json();
      setSub(body);
    }
  }

  const completed = async () => {
    try {
      openModal();
      const res = await window.authFetch(`subs/${id}/completed`, {
        method: "put"
      });
      if(res.ok){
        history.push(`/courses/${sub.course_id}`);
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  React.useEffect(() => {
    fetchSub();
  }, []);

  return{
    sub,
    completed
  }
}

export default useSub;
