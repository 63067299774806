import React from "react";
import useTutors from "hooks/admin/useTutors";
import Navbar from "components/admin/Navbar.js";
import {Link} from "react-router-dom";
import Table from "components/admin/Table";

const Tutor = () => {

  const {
    tutors,
  } = useTutors();

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2>Tutors</h2>
          <div>
            <Link to="/admin/tutors/new" className="btn btn-primary login-btn-primary">New Tutor</Link>
          </div>
        </div>
        <Table
          headers={[
            {title: "Name"},
            {title: "Email"},
            {title: "Courses", key: "courses_count"},
          ]}
          rows={tutors}
          resource="tutors"
          actions
        />
      </div>
    </>
  );
}

export default Tutor;
