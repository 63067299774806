import React from "react";
import {
  Link
} from "react-router-dom";

const BackButton = ({id}) => {

  return <Link to={`/courses/${id}`} className="btn btn-outline-primary">Back</Link>

}

export default BackButton;
