import React from "react";
import Rating from "components/Rating";

import useCourse from "hooks/useCourse"
import { AppContext } from "App";

import CourseUp from "components/CourseUp";
import CourseInformation from "components/CourseInformation";
import Navbar from "components/Navbar";
import CourseReviews from "components/CourseReviews";

import {
  Link
} from "react-router-dom";
import ActivityPage from "components/ActivityPage";


const CourseCheck = ({sub, sectionIndex, subIndex, setCourse, course}) => {

  const {
    openModal,
    closeModal,
    openErrorDialog,
  } = React.useContext(AppContext);

  const onCheck = async () => {
    try {
      openModal();
      const oldSub = course.sections[sectionIndex].subs[subIndex];
      const res = await window.authFetch(`subs/${sub.id}/completed`, {
        method: oldSub.completed ? "delete" : "put"
      });
      if(res.ok){
        course.sections[sectionIndex].subs[subIndex].completed = !oldSub.completed;
        setCourse({ ...course });
      }
    } catch (e) {
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  return(
    <div className="form-check">
      <div className="mb-0">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" checked={sub.completed} onClick={onCheck} />
          <Link to={`/subs/${sub.id}`} className="d-block" style={{color: "inherit"}}>
            <label className="form-check-label"> {sub.title} </label>
          </Link>
        </div>
      </div>
    </div>
  )
}

const ShowCourse = () => {

  const {
    addToCart,
    canAddToCart,
    currentUser
  } = React.useContext(AppContext);

  const {
    course,
    setCourse,
    isLoading,
    onDownloadCertificate
  } = useCourse();

  if(isLoading) return (<ActivityPage />);

  const {
    id,
    title,
    description,
    sections,
    reviews,
    price
  } = course;

  const onAddToCart = () => addToCart(course);


  return(
    <>
      <Navbar />
      <CourseUp title={title} />
      <section className="section">
          <div className="container">
              <div className="row">
                  <div className="col-lg-4 col-md-5 col-12">
                    <CourseInformation course={course} />
                    <div className="mt-4">
                      {
                        canAddToCart(course) ?
                          <button onClick={onAddToCart} className="btn btn-outline-primary">Add to cart <i className="mdi mdi-send"></i></button>
                        : null
                      }
                    </div>
                    <div className="mt-4">
                      {
                        course.completed ?
                          <button className="btn btn-outline-primary" onClick={onDownloadCertificate}>Download Certificate</button>
                        : null
                      }
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="ms-lg-4">
                          <h5>Course Description: </h5>
                          <p className="description text-muted">{course.description}</p>
                      </div>
                      <div className="ms-lg-4">
                       <div className="col mt-4 pt-2" id="accordions">
                          <div className="component-wrapper rounded shadow">
                            <div className="p-4 border-bottom">
                              <h4 className="title mb-0">Course Content</h4>
                            </div>

                            <div className="p-4">
                              <div className="accordion mt-4 pt-2" id="buyingquestion">
                                {
                                  course.sections.map((section, i) => (
                                    <div className="accordion-item rounded">
                                      <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${section.id}`} aria-expanded="false" aria-controls="collapseOne">
                                          {i+1}. {section.title}
                                        </button>
                                      </h2>
                                      <div id={`collapse${section.id}`} className="accordion-collapse border-0 collapse" aria-labelledby="headingOne" data-bs-parent="#buyingquestion">
                                        <div className="accordion-body text-muted bg-white">
                                          {
                                            section.subs.map((sub, j) => (
                                                <React.Fragment key={j}>
                                                  {
                                                    course.owned ?
                                                      <CourseCheck
                                                        sub={sub}
                                                        sectionIndex={i}
                                                        subIndex={j}
                                                        course={course}
                                                        setCourse={setCourse}
                                                      />
                                                    :
                                                      <div className="form-check" style={{color: "inherit"}}>
                                                        <div className="mb-0">
                                                          <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={sub.completed} />
                                                            <label className="form-check-label"> {sub.title} </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                  }
                                                </React.Fragment>
                                            ))
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>

                          </div>
                        </div>
                        <CourseReviews
                          course={course}
                          currentUser={currentUser}
                          setCourse={setCourse}
                        />

                      </div>
                  </div>
              </div>
          </div>
        </section>
    </>
  )
}

export default ShowCourse;
