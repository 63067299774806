import React from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom";
import {
  CourseContext
} from "views/admin/ShowCourse";
import {AppContext} from "App";

const useSectionForm = (section, i, id) => {

  const {
    setCourse,
    course
  } = React.useContext(CourseContext);

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [isSubmitting, setIsSubmitting] = React.useState();
  const [isFetching, setIsFetching] = React.useState();
  const [isEditing, setIsEditing] = React.useState();

  const [asyncErrors, setAsyncErrors] = React.useState({});

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      openModal();
      let url = isEditing ? `admin/sections/${section.id}` : `admin/courses/${course.id}/sections`;
      let method = isEditing ? "put" : "post";
      const res = await window.authFetch(url, {
        method,
        body: JSON.stringify(values)
      });
      if(res.ok){
        const body = await res.json();
        if(isEditing){
          course.sections[i] = body;
        }else{
          course.sections.push(body);
        }
        setCourse({ ...course });
      }else if (res.status == 422) {
        const body = await res.json();
        setAsyncErrors({...body});
      }else throw new Error();
    } catch (e) {
      openErrorDialog();
    } finally {
      closeModal();
      setIsSubmitting(false);
      let el = document.getElementById(id);
      var modal;
      modal = window.bootstrap.Modal.getInstance(el);
      modal.hide();
    }
  }

  React.useEffect(() => {
    if(section.id){
      setIsEditing(true);
    }
  }, []);


  return{
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    isFetching
  }

}

export default useSectionForm;
