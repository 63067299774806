import React from "react";
import { useParams } from "react-router-dom";

const useArticle = () => {

  const {
    id
  } = useParams();

  const [article, setArticle] = React.useState();

  const fetchArticle = async () => {
    let token = localStorage.getItem("token");
    const res = await window.authFetch(`admin/articles/${id}`);
    if(res.status == 200){
      const body = await res.json();
      setArticle(body);
    }
  }

  React.useEffect(() => {
    fetchArticle();
  }, []);

  return{
    article,
    setArticle
  }
}

export default useArticle;
