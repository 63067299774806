import React from "react";
import Navbar from "components/admin/Navbar";
import TextField from "components/BaseTextField";
import useMasterClassForm from "./useMasterClassForm";
import { Formik } from 'formik';
import * as Yup from "yup";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import handleSetImage from "lib/handleSetImage";
import { AppContext } from "App";
import DatePicker from "components/DatePicker";
import Editor from "components/admin/Editor";

const MasterClassFormSchema = Yup.object().shape({
    title: Yup.string()
        .required(),

    description: Yup.string()
        .required(),
    price: Yup.number()
        .required(),
    start_date: Yup.string()
        .required(),
    limit: Yup.number()
        .required(),
    category_id: Yup.number()
        .required(),
});

const MasterClassForm = () => {

    const {
        currentUser
    } = React.useContext(AppContext);

    const {
        asyncErrors,
        onSubmit,
        isSubmitting,
        isEditing,
        crop,
        setCrop,
        image,
        setImage,
        src,
        setSrc,
        masterClass,
        isFetching,
        categories,

    } = useMasterClassForm();

    const onSetImage = ({ target }) => handleSetImage({ target, setSrc });

    if (isFetching) return null;

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-8">
                        <div className="card login-page bg-white shadow rounded border-0">
                            <div className="card-body">
                                <h4 className="card-title">{isEditing ? "Edit Master Class" : "New Master Class"}</h4>
                                <Formik
                                    initialValues={masterClass}
                                    validationSchema={MasterClassFormSchema}
                                    onSubmit={onSubmit}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    className="login-form mt-4"
                                >
                                    {
                                        ({ handleSubmit, ...form }) => {

                                            const {
                                                values,
                                                handleChange
                                            } = form;
                                            return <form onSubmit={handleSubmit}>
                                                <div className="row">

                                                    {
                                                        src ?
                                                            <>
                                                                <ReactCrop
                                                                    src={src}
                                                                    crop={crop}
                                                                    onChange={setCrop}
                                                                    onImageLoaded={setImage}
                                                                />

                                                                <div style={{ position: "relative" }} className="my-4">
                                                                    <button type="submit" className="btn btn-primary">Upload cover image</button>
                                                                    <input type="file" onChange={onSetImage} name="image" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, opacity: 0 }} />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div style={{ position: "relative" }} className="my-4">
                                                                    <img src={masterClass.image || "/placeholder.jpg"} />
                                                                    <input type="file" onChange={onSetImage} name="image" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, opacity: 0 }} />
                                                                </div>
                                                            </>
                                                    }

                                                    <TextField
                                                        name="title"
                                                        form={form}
                                                        asyncErrors={asyncErrors}
                                                        label="Title"
                                                    />

                                                    <TextField
                                                        name="description"
                                                        form={form}
                                                        asyncErrors={asyncErrors}
                                                        label="Description"
                                                        multiline
                                                    />

                                                    <TextField
                                                        name="price"
                                                        form={form}
                                                        asyncErrors={asyncErrors}
                                                        label="Price"
                                                    />

                                                    <TextField
                                                        name="limit"
                                                        form={form}
                                                        asyncErrors={asyncErrors}
                                                        label="Limit"
                                                    />

                                                    <DatePicker
                                                        form={form}
                                                        label="Start Date"
                                                        name="start_date"
                                                    />

                                                    <div className="mb-3">
                                                        <label className="form-label">Time</label>
                                                        <div className="row">

                                                            <TextField
                                                                name="time_at"
                                                                form={form}
                                                                asyncErrors={asyncErrors}
                                                                size={6}
                                                            />

                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <div className="form-icon position-relative">
                                                                        <select
                                                                            name="meridiem"
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            onChange={handleChange}
                                                                            value={values.meridiem}
                                                                        >
                                                                            <option value="AM">AM</option>
                                                                            <option value="PM">PM</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Category <span className="text-danger">*</span></label>
                                                            <div className="form-icon position-relative">
                                                                <select
                                                                    name="category_id"
                                                                    className="form-control"
                                                                    autocomplete="off"
                                                                    onChange={handleChange}
                                                                    value={values.category_id}
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    {
                                                                        categories.map((category, i) => <option key={i} value={category.id}>{category.name}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Editor
                                                        onChange={form.handleChange("more")}
                                                        body={form.values.more}
                                                        isEditing={isEditing}
                                                    />

                                                    <div className="col-lg-12 mb-0">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-primary">{isEditing ? "Update MasterClass" : "Add MasterClass"}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MasterClassForm;
