import React from 'react';
import {AppContext} from "App";

const DeleteSectionForm = ({id, path, title, onComplete}) => {

  const {
    openModal,
    closeModal,
    openErrorDialog,
  } = React.useContext(AppContext);

  const onDelete = async () => {
    try {
      openModal();
      const res = await window.authFetch(path, {
        method: "delete"
      });
      if(res.status == 200){
        var myModal = window.bootstrap.Modal.getInstance(document.getElementById(id));
        myModal.hide();
        onComplete();
      }
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  return(
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Delete {title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            Are you sure you want to delete this?
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={onDelete}>Yes</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteSectionForm;
