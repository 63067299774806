import React from "react";
import Navbar from "components/admin/Navbar";
import usePages from "./usePages";
import Table from "components/admin/Table";
import { Link } from "react-router-dom";

const Pages = () => {

    const {
        pages
    } = usePages();

    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h2>Pages</h2>
                </div>
                <Table
                    headers={[
                        { title: "Name" },
                    ]}
                    rows={pages}
                    actions
                    resource="pages"
                />
            </div>
        </>
    )
}

export default Pages;