import React from "react";
import useUsers from "./useUsers";
import Navbar from "components/admin/Navbar.js";
import Table from "components/admin/Table";

const Users = () => {

  const {
    users,
  } = useUsers();

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2>Users</h2>
          <div>
            <a href={users.export_url} className="btn btn-primary login-btn-primary">Export</a>
          </div>
        </div>
        <Table
          headers={[
            {title: "Name"},
            {title: "Email"},
            {title: "Courses", key: "courses_count"},
          ]}
          rows={users.users}
          resource="users"
          actions
        />
      </div>
    </>
  );
}

export default Users;
