import React, { useContext, useState } from "react";
import { useItemFinalizeListener, useItemFinishListener, useItemProgressListener, useItemStartListener, useItemErrorListener } from "@rpldy/chunked-uploady";
import UploadButton from "@rpldy/upload-button";
import { AppContext } from "App";
import Activity from "./Activity";

export default function UploadButtonC(props) {

    let { setSrc } = props;

    const [text, setText] = useState("0%");

    useItemStartListener((item) => {
        openModal()
    })

    useItemFinishListener((item) => {
        try {
            let results = item.uploadResponse.results
            let count = results.length;
            let url = results[count - 1].data.path;
            setSrc(url);
        } catch (error) {
            console.log(error);
        } finally {

            closeModal();
        }
    })

    useItemProgressListener((item) => {
        setText(`${item.completed.toFixed(2)}%`)
    })

    useItemErrorListener(() => {
        closeModal();
    })

    const openModal = () => {
        var myModal = new window.bootstrap.Modal(document.getElementById('activity2'), { backdrop: 'static' });
        myModal.show();
    }

    const closeModal = () => {
        var myModal = window.bootstrap.Modal.getInstance(document.getElementById('activity2'), { backdrop: 'static' });
        myModal.hide();
    }

    return (
        <>
            <UploadButton
                type="button"
                className="btn"
            >
                Upload video
            </UploadButton>
            <Activity 
                text={text}
            />
        </>
    )

}