const Header = ({ title, description }) => (
  <section className="bg-half-170 d-table w-100" data-jarallax='{"speed": 0.5}' style={{ background: "url('/images/crypto/stor_background.png') top", backgroundSize: "cover" }}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 mt-5 pt-md-5">
          <div className="title-heading text-center">
            <h4 className="heading text-white title-dark mb-4">{title || `Quality Courses. Great Price.`}</h4>
            <p className="text-light para-dark para-desc mx-auto">{description || `Ace your exams with bite-sized courses, curated by world-class accounting and finance
              professionals who were once like you`}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Header;
