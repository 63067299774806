import React from "react";
import {
  Link
} from "react-router-dom";
import { AppContext } from "App";
import Navbar from "components/Navbar";
import UserShort from "components/UserShort";
import UserLinks from "components/UserLinks";
import useNotifications from "hooks/useNotifications";

const Notifications = () => {

  const {
    currentUser = { },
    signOut,
  } = React.useContext(AppContext);

  const {
    notifications
  } = useNotifications();

  return(
    <>
      <Navbar />
      <UserShort
        currentUser={currentUser}
      />
      <section className="section mt-60">
        <div className="container mt-lg-3">
          <div className="row">
            <UserLinks />
            <div className="col-lg-8 col-12">
              <div className="card border-0 rounded shadow">
                <div className="card-body">
                    <h5 className="text-md-start text-center">Notifications</h5>
                    <ul>
                        {
                            notifications.map((notification, i) => {
                                return(
                                    <Link to={notification.link} className="d-block p-4 border-bottom">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <a className="pe-3" href="#">
                                                    <img src={notification.user.avatar} className="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img" />
                                                </a>
                                                <div className="flex-1 commentor-detail">
                                                    <h6 className="mb-0">
                                                        <a className="media-heading text-dark">{notification.user.name}</a>
                                                    </h6>
                                                </div>
                                            </div>
                                            <small className="text-muted">{notification.date} <br /> at {notification.time}</small>
                                        </div>
                                        <div className="mt-3">
                                            <p className="text-muted mb-0">{notification.comment}</p>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>
     </>
  )
}

export default Notifications;
