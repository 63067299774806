import React from "react";
import { AppContext } from "App";
import { useHistory } from "react-router-dom";
import moment from "moment";

const MasterClassSide = ({ course, index }) => {

    const {
        addToCart,
        canAddToCart,
        removeItem
    } = React.useContext(AppContext);

    const history = useHistory();

    const onAddToCart = () => addToCart(course, "masterclass");
    const onRemoveItem = () => removeItem(course, index);

    const onNavigate = () => {
        history.push(`/masterclasses/${course.id}`)
    }

    let month = moment(course.started_at).format("MMM")
    let day  = moment(course.started_at).format("D")

    let time = moment.utc(course.started_at).format("HHmm");

    return (
        <tr onClick={onNavigate}>
            <td>
                <p className="m-0">
                    <div style={{
                    }}>
                        <span
                            style={{
                                backgroundColor: "#FF6600",
                                padding: "2px 0",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                color: "#fff",
                                display: "block",
                                textAlign: "center"
                            }}
                        >
                            {month}
                        </span>
                        <span
                            style={{
                                fontSize: "30px",
                                borderBottomLeftRadius: "10px",
                                borderBottomRightRadius: "10px",
                                border: "thin solid #bbb",
                                display: "block",
                                textAlign: "center"
                            }}
                        >
                            {day}
                        </span>
                    </div>
                </p>
                <p className="text-muted m-0">{`${time} GMT`}</p>
            </td>
            <td>
                <img src={course.image} className="img-fluid" alt={course.title} width="200px" />

            </td>
            <td>
                <h5 className="mb-0">
                    {course.title}
                </h5>
                <p className="text-muted">{course.description}</p>

            </td>
            <td>{`$${course.price}`}</td>
            <td>
                <div className="">
                    {
                        canAddToCart(course, true) ?
                            <button onClick={onAddToCart} className="btn btn-outline-primary">Add to Cart</button>
                            : null
                    }
                </div>
            </td>
        </tr>
    )
}

export default MasterClassSide;