import React from "react";
import useQuestionForm from "hooks/admin/useQuestionForm";
import Answer from "components/admin/Answer";
import TextField from "components/BaseTextField";


const QuestionForm = ({id, test, q, i}) => {

  const {
    qRef,
    onSetAnswer,
    aRef,
    onAddAnswer,
    onCreateQuestion,
    question,
    answerError,
    questionError,
    isEditing,
    handleDelete
  } = useQuestionForm(test, id, q, i);

  return (

    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{isEditing ? "Edit Question" : "New Question"}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <TextField
              ref={qRef}
              label="Question"
              placeholder="Question"
              helperText={questionError}
              error={Boolean(questionError)}
              multiline
            /><br />
            <ol style={{listStyleType: "lower-alpha"}}>
              {
                question.answers.map((answer, i) => {

                  const handleSetAnswer = () => onSetAnswer(i)

                  const onDelete = () => handleDelete(i)

                  return(
                    <Answer
                        body={answer}
                        onSetAnswer={handleSetAnswer}
                        isChecked={i == question.answer}
                        onDelete={onDelete}
                      />
                  )
                })
              }
              <li className="mt-2">
                <div className="mb-0 row align-items-center justify-content-between">
                  <TextField
                    ref={aRef}
                    label="Answer"
                    placeholder="Answer"
                    helperText={answerError}
                    error={Boolean(answerError)}
                    multiline
                   />
                 <div className="ms-3">
                   <button type="button" className="btn btn-outline-primary" onClick={onAddAnswer}>Add Answer</button>
                 </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={onCreateQuestion}>{isEditing ? "Update" : "Add"}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionForm;
