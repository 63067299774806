import React from "react";
import {AppContext} from "App";
import {CourseContext} from "views/admin/ShowCourse";

export const Editable = (props) => {

  const {
    isTutor,
  } = React.useContext(AppContext);

  const courseContext = React.useContext(CourseContext);

  let handleChange;

  if(courseContext){
    handleChange = courseContext.onChange;
  }

  const {
    text,
    Componet,
    ComponetProps,
    color ="dark",
    multiline,
    placeholder,
    select,
    options,
    ...others
  } = props;

  const [isEditing, setIsEditing] = React.useState();
  const [value, setValue] = React.useState(text);
  const [selectText, setSelectText] = React.useState(text);

  const onEdit = () => setIsEditing(true);

  const onSave = () => {
    setIsEditing(false);
    handleChange({ name: placeholder.toLowerCase(), value });
  }

  const onChange = ({target}) => {
    if(select){
      const v = JSON.parse(target.value);
      setValue(v.value);
      setSelectText(v.name);
    }else setValue(target.value)
  };

  const findValue = (value) => {
    let v = options.filter((option) => option.value == value)[0];
    return JSON.stringify(v);
  }

  const C = multiline ? "textarea" : "input";

  if(isEditing && courseContext.course.state != "Approved"){
    return(
      <>
        {
          select ?
          <select
            className="form-control"
            value={findValue(value)}
            onChange={onChange}
          >
            {
              options.map((option, i) => <option key={i} value={JSON.stringify(option)}>{option.name}</option>)
            }
          </select>
          :
          <C
            className="form-control"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            {...others}
          />
        }
        {
          isTutor() ?
          <button
            onClick={onSave}
            className={`btn btn-icon text-${color} ms-3`}
            style={{fontSize: "30px"}}
          >
            <i className="uil uil-save"></i>
          </button>
          : null
        }
      </>
    )
  }

  return(
    <>
      <Componet {...ComponetProps}>
        {
          select ?
          selectText
          :
          value
        }
        {
          isTutor() && !courseContext.isApproved() ?
          <button
            onClick={onEdit}
            className={`btn btn-icon text-${color} ms-3`}
            style={{fontSize: "30px"}}
          >
            <i className="uil uil-edit"></i>
          </button>
          : null
        }
      </Componet>

    </>
  )
}

export default Editable;
