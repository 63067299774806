import React from "react";
import {AppContext} from "App";
import toBase64 from "lib/toBase64";

const usePersonalDetails = (image, crop) => {

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const onSubmit = async (values) => {
    try {
      openModal();
      if(image) var file = toBase64(image, crop);
      const res = await window.authFetch(`user`, {
        method: "put",
        body: JSON.stringify({
          ...values,
          file
        })
      });
      if(res.status == 200){
        const body = await res.json();
        setCurrentUser(body);
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  }

  return{
    onSubmit
  }
}

export default usePersonalDetails;
