import React from "react";
import {
  useParams
} from "react-router-dom";

const useSections = () => {

  const {
    id
  } = useParams();

  const [sections, setSections] = React.useState([]);
  const [course, setCourse] = React.useState({});


  const fetchSections = async (values) => {
    try {
      const res = await window.authFetch(`admin/courses/${id}/sections`);
      if(res.status == 200){
        const body = await res.json();
        setSections(body.sections);
        setCourse(body.course);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchSections();
  }, []);


  return{
    fetchSections,
    sections,
    course
  }

}

export default useSections;
