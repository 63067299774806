import React from "react";

const Answer = ({body, onSetAnswer, isChecked, onDelete}) => {

  return(
     <li className="mt-2 d-flex align-items-center">
       <div className="custom-control custom-radio custom-control-inline"  onClick={onSetAnswer}>
         <div className="mb-0">
           <input
             className="form-check-input"
             type="radio"
             className="flexRadioDefault me-2"
             checked={isChecked}
           />
          <label className="form-check-label">{body}</label>
         </div>
       </div>
       {
         onDelete ?
         <button className="btn btn-icon ms-4" onClick={onDelete}>
           <i className="uil uil-trash"></i>
         </button>
         : null
       }
     </li>
  )
}

export default Answer;
