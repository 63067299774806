import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer footer-border">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <a href="#" className="logo-footer">
                <img src="images/white_logo.png" height="75" alt="" />
              </a>
              <p className="mt-4">Qui Audet Adipiscitur <br /> Dare To Do</p>
              <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                <li className="list-inline-item"><a href="" className="rounded"><i data-feather="facebook" className="fea icon-sm fea-social"></i></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/storinstitute/" className="rounded"><i data-feather="instagram" className="fea icon-sm fea-social"></i></a></li>
                <li className="list-inline-item"><a href="https://twitter.com/storinstitute" className="rounded"><i data-feather="twitter" className="fea icon-sm fea-social"></i></a></li>
                <li className="list-inline-item"><a href="https://www.linkedin.com/company/stor-institute" className="rounded"><i data-feather="linkedin" className="fea icon-sm fea-social"></i></a></li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head">General</h5>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <Link to="/about" className="text-foot">
                    <i className="uil uil-angle-right-b me-1"></i>
                    About us
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head">Useful Links</h5>
              <ul className="list-unstyled footer-list mt-4">
                {
                  [
                    {
                      name: "Terms and Conditions",
                      to: "/pages/terms-and-conditions",
                    },
                    {
                      name: "Privacy Policy",
                      to: "/pages/privacy-policy",
                    },
                  ].map((link, i) => (
                    <li key={i}>
                      <Link to={link.to} className="text-foot">
                        <i className="uil uil-angle-right-b me-1"></i>
                        {link.name}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head">Contact</h5>
              <ul className="list-unstyled footer-list mt-4">
                <li><a className="text-foot" href="mailto:info@storinstitute.org">Email: info@storinstitute.org</a></li>
                <li><a href="tel:+233 (0) 25 705 9398" className="text-foot">Phone: +233 (0) 25 705 9398</a></li>
                <li><a href="https://wa.link/4jfxze" className="text-foot">WhatsApp: +233 (0) 25 705 9398</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text-sm-start">
                <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> Copyright © 2022 STOR Institute. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" onClick={() => document.topFunction()} id="back-to-top" className="btn btn-icon btn-primary back-to-top"><i data-feather="arrow-up" className="icons"></i></a>

    </>
  )
}

export default Footer;
