import React from "react";
import Editable from "components/admin/Editable";

const CourseUp = ({
  title,
  description
}) => {

  return(
    <>
      <section className="bg-half bg-light d-table w-100" style={{"background": "url('/images/crypto/stor_background.png') top"}}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="page-next-level text-white">
                <Editable placeholder="Title" Componet="h4" ComponetProps={{className: "title mt-4 mb-3"}} text={title} color="white" />
                <p className="para-desc mx-auto text-default">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

    </>
  )
}

export default CourseUp;
