import useFetch from "lib/useFetch";
import React from "react";

const useUsers = () => {

  const [users, setUsers] = React.useState({
    users: []
  });

    const {
        onFetch
    } = useFetch(`/admin/users`, setUsers)

  React.useEffect(() => {
    onFetch();
  }, []);


  return{
    users
  }

}

export default useUsers;
