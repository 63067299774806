import React from "react";
import {Link} from "react-router-dom";
import Button from "components/BaseButton";
import BackButton from "components/BackButton";

const Tabs = () => (
  <ul className={'nav nav-pills nav-justified flex-column flex-sm-row rounded'} id="pills-tab" role="tablist">
    <li className={'nav-item'}>
      <a className={'nav-link rounded active'} id="pills-cloud-tab" data-bs-toggle="pill" href="#pills-cloud" role="tab" aria-controls="pills-cloud" aria-selected="true">
        <div className={'text-center py-2'}>
          <h6 className={'mb-0'}>Overview</h6>
        </div>
      </a>
    </li>
    <li className={'nav-item'}>
      <a className={'nav-link rounded'} id="pills-apps-tab" data-bs-toggle="pill" href="#pills-apps" role="tab" aria-controls="pills-apps" aria-selected="false">
        <div className={'text-center py-2'}>
          <h6 className={'mb-0'}>Learning tools</h6>
        </div>
      </a>
    </li>
  </ul>
)

const TabPane = ({video}) => (
  <div className={'tab-pane fade active show'} id="pills-cloud" role="tabpanel" aria-labelledby="pills-cloud-tab">
    <div className={'container'}>
      <div className={'row justify-content-center'}>
        <div className={'bg-light rounded p-4'}>
          <div className={'bg-light rounded p-4'}>
            <h4 className={'title mb-0'}>Lecture description</h4>
            {video.description}
          </div>
        </div>
      </div>
    </div>
  </div>
)

const TabPane2 = ({video}) => (
  <div className={'tab-pane fade'} id="pills-apps" role="tabpanel" aria-labelledby="pills-apps-tab">
    <div className={'accordion mt-4 pt-2'} id="resourcedownload">
      <div className={'accordion-item rounded'}>
        <h2 className={'accordion-header'} id="resourceOne">
          <button className={'accordion-button border-0 bg-light collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#storcollapseOne" aria-expanded="false" aria-controls="storcollapseOne">
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className={'bi bi-folder'} viewBox="0 0 16 16">
              <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z" />
            </svg>
            Download resources...
          </button>
        </h2>
        <div id="storcollapseOne" className={'accordion-collapse border-0 collapse'} aria-labelledby="resourceOne" data-bs-parent="#resourcedownload">
          <div className={'accordion-body text-muted bg-white'}>
            <ul className={'list-unstyled text-muted mt-4'}>
              {
                video.resources.map((resource, i) => {
                  return(
                    <li key={i} className={'mb-0'}>
                      <a href={resource.path} download>{resource.name}</a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)


export const VideoSidebar = ({video, isVideo}) => (
  <div className={'vicontainer-sidebar'}>
    <div className={'vicontainer-sidebar-header'}>
      <h4>Course content</h4>
      <button className={'sidebar-close'} onClick={() => isVideo ? window.onCloseVideoSidebar() : window.onCloseTextVideoSidebar()}>x</button>
    </div>
    <div className={'vicontainer-sidebar-content'}>
      <div className={'p-4'}>
        <div className={'accordion mt-4 pt-2'} id="buyingquestion">
          {
            video.sections.map((section, i) => {
              return(
                <div className={'accordion-item rounded mt-2'} key={i}>
                  <h2 className={'accordion-header'} id={section.id}>
                    <button className={'accordion-button border-0 bg-light collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${section.id}`} aria-expanded="false" aria-controls="collapseFour">
                      {i+1} { section.title }
                    </button>
                  </h2>
                  <div id={`collapse${section.id}`} className={'accordion-collapse border-0 collapse'} aria-labelledby="headingFour" data-bs-parent="#buyingquestion">
                    <div className={'accordion-body text-muted bg-white'}>
                      {
                        section.subs.map((sub, j) => {
                          return (
                            <div key={j} class="form-check">
                                <div class="mb-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" checked="" value=""
                                            id="flexCheckOption1" />
                                        <label class="form-check-label" for="flexCheckOption1">
                                          <a href={`/subs/${sub.id}`} style={{color: "inherit"}}>{sub.title}</a>
                                        </label>
                                    </div>
                                </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  </div>
)

const VideoTabs = ({video}) => (
  <div className={'vicontainer-video-tabs'}>
    <div className={'vicontainer-video-tabs-inner'}>
      <div className={'p-4'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <Tabs />
          </div>
        </div>
        <div className={'row pt-2'}>
          <div className={'col-12'}>
            <div className={'tab-content'} id="pills-tabContent">
              <TabPane video={video} />
              <TabPane2 video={video} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const Video = ({ sub: video, completed }) => {

  return(
    <>
      <section className={'section'}>
        <div className={'vicontainer card shadow rounded border-0 overflow-hidden'}>
          <VideoSidebar video={video} isVideo />
          <div className={'vicontainer-video-wrapper'}>
            <div className={'vicontainer-video-player'}>
              <div className="vicontainer-video-player-header">
                <div className="vicontainer-video-player-header-left" onClick={window.onCloseVideoSidebar}>
                  <ul>
                    <li><i className="uil uil-list-ul"></i></li>
                    <li className="sidebar-close closer"></li>
                  </ul>
                </div>
                <div className="vicontainer-video-player-header-right">
                  <h2>{video.title}</h2>
                </div>
              </div>

              <video controls className="responsive-iframe" src={video.path} />
              <div className={'vicontainer-video-tabs-sidebar-close'}>
                <span className={'badge rounded-pill bg-dark sidebar-close closer'}> close </span>
              </div>
            </div>
            <VideoTabs video={video} />
            <div className="mt-4 d-flex">
              <BackButton id={video.course_id} />
              {
                !video.completed ?
                  <div className="ms-3">
                    <Button onClick={completed} >Completed</Button>
                  </div>
                : null
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Video;
