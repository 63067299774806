import React from "react";

import { Link, NavLink } from "react-router-dom";

import Header from "../components/Header";
import Aside from "../components/Aside";
import Footer from "../components/Footer";

import useHome from "../hooks/useHome";

import Navbar from "components/Navbar";

export const Card = ({ category }) => (
  <Link to={`#`} className="col-lg-3 col-md-4 mt-4 pt-2 d-block text-dark">
    <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
      <div className="card-body p-0 content">
        <h5>{category}</h5>
        {/* <p className="para text-muted mb-0"> 33,145,974 students</p> */}
      </div>
      <span className="big-icon text-center">
        <i className="uil uil-chart-line"></i>
      </span>
    </div>
  </Link>
);

const Course = ({ course }) => {
  return (
    <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
      <Link to={`/courses/${course.id}`}>
        <div className="card blog rounded border-0 shadow overflow-hidden">
          <div className="position-relative">
            <img src={`${course.image}`} className="card-img-top" alt="..." />
            <div className="overlay bg-dark"></div>
          </div>
          <div className="position-relative">
            <div className="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div className="card-body content">
            <small className="text-primary h6">{course.category}</small>
            <h5 className="mt-2 title text-dark">{course.title}</h5>
          </div>
        </div>
      </Link>
    </div>
  )
}

const TopCourses = ({ topCourses }) => (
  <section className="section">
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-4">Top Courses</h4>
          </div>
        </div>
      </div>
      <div className="row">
        {
          topCourses.map((course, i) => <Course key={i} course={course} />)
        }
      </div>
    </div>
  </section>
)

const PopularCourses = ({ popularCourses }) => (
  <section className="section">
    <div className="container mt-100 mt-60">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-4">Most Popular Courses</h4>
          </div>
        </div>
      </div>
      <div className="row">
        {
          popularCourses.map((course, i) => <Course key={i} course={course} />)
        }
      </div>
    </div>
  </section>
)

export const Categories = ({ categories }) => (
  <section className="section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="section-title mb-4 pb-2">
            <h4 className="title mb-4">Our Catalogue</h4>
          </div>
        </div>
      </div>
      <div className="row">
        {
          categories.map((category, i) => <Card key={i} category={category} />)
        }
      </div>
    </div>
  </section>
)

export const Once = ({ text }) => (

  <section className="section bg-light">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-5 col-md-6">
          <img src="images/illustrator/faq.svg" className="img-fluid" alt="" />
        </div>
        <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div className="section-title ms-lg-5">
            <h4 className="title mb-4">We Were Once Like You</h4>
            <p className="text-muted">We draw on our technical know-how, and combined years of
              experience in industry, to provide you with all you need to pass your
              exams in one place.</p>
            <p className="text-muted">{text || `Having sat these same exams, we know what our students need and
          are here to support you every step of the way`}</p>
          </div>
          <div className="section-title ms-lg-5 mt-5">
            <h4 className="title mb-4">Where we've worked</h4>
            <div className="row">
              {
                [...Array(6)].map((j, i) => (
                  <div key={i} className="col-lg-2 d-flex align-items-end">
                    <img src={`/images/cs/${i + 1}.png`} className="img-fluid" />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const Home = () => {

  const {
    topCourses,
    popularCourses,
    categories
  } = useHome();

  React.useEffect(() => {
    window.feather.replace();
  }, []);

  return (
    <>
      <Navbar />
      <Header />
      <section className="section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 section-title">
              <h4 id="top" className="mb-4" style={{ lineHeight: "1" }}>the future of study is here</h4>
              <p>Study what you want at the click of a button, with all the support
                you need to go with it. Professional knowledge and skills are not
                reserved for a select few.</p>
              <ul className="list-unstyled">
                <li className="mt-2"><span style={{ color: "green" }}><i data-feather="check-circle"></i></span><span className="ms-2">No need to break the bank while at it</span></li>
                <li className="mt-2"><span style={{ color: "green" }}><i data-feather="check-circle"></i></span><span className="ms-2">Tuition delivered by leaders in their practice areas</span></li>
              </ul>
              <Link className="btn btn-primary mt-5" to="/courses">Catalogue</Link>
            </div>
            <div className="col-lg-1">

            </div>
            <div className="col-lg-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="row">
                <div className="col-lg-6">
                  <img src="images/cs/07.jpg" alt="" className="rounded img-fluid" />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-between">
                  <img src="images/blog/06.jpg" alt="" className="rounded img-fluid" />
                  <img src="images/blog/04.jpg" alt="" className="rounded img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Aside />
      <PopularCourses popularCourses={popularCourses} />
      <Categories categories={[
        "ACCA",
        "CFA",
        "CISI",
        "OBU"
      ]} />
      <Once />
      <Footer />
    </>
  )
}

export default Home;
