import React from 'react';
import {Editable} from "./CourseUp";

const CourseInformation = ({course}) => {

  React.useEffect(() => {
    window.feather.replace();
  }, []);

  return (
    <div className="card sidebar rounded shadow border-0">
        <div className="card-body widget border-bottom">
            <h5 className="mb-0">Course Information</h5>
        </div>

        <div className="card-body">

            <div className="d-flex widget align-items-center mt-3">
                <i data-feather="monitor" className="fea icon-ex-md me-3"></i>
                <div className="flex-1">
                    <h6 className="widget-title mb-1">Category:</h6>
                    <p className="text-primary mb-0">{course.category}</p>
                </div>
            </div>

            {
                course.owned ?
                    null
                : 
                <div className="d-flex widget align-items-center mt-3">
                    <span className="fea icon-ex-md me-3">$</span>
                    <div className="flex-1">
                        <h6 className="widget-title mb-1">Price:</h6>
                        <p className="text-primary mb-0">{course.price}</p>
                    </div>
                </div>
            }

            <div className="d-flex widget align-items-center mt-3">
                <i data-feather="clock" className="fea icon-ex-md me-3"></i>
                <div className="flex-1">
                    <h6 className="widget-title mb-1">Duration</h6>
                    <p className="text-primary mb-0">{course.duration}</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default CourseInformation;
