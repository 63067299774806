import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {AppContext} from "App";

const useNewVideo = () => {

  const history = useHistory();

  const { id } = useParams();

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [asyncErrors, setAsyncErrors] = React.useState({

  });

  const onSubmit = async (values) => {
    try {
      openModal();
      const res = await window.authFetch(`admin/sections/${id}/tests`, {
        method: "post",
        body: JSON.stringify(values)
      });
      if(res.ok){
        const body = await res.json();
        history.push(`/admin/courses/${body.course_id}`);
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      closeModal();
    }
  }


  return{
    onSubmit,
    isSubmitting,
    asyncErrors,
  }

}

export default useNewVideo;
