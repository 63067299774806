import React from "react";
import Ratings from "components/Rating";
import Replies from "components/Replies";
import ReplyForm from "./ReplyForm";
import { AppContext } from "App";

const Review = ({review, ...others}) => {

  const {
    isTutor,
  } = React.useContext(AppContext);

  return(
    <div className="p-4 border-bottom">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <a className="pe-3" href="#">
          <img src={review.user.avatar} className="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img" />
          </a>
          <div className="flex-1 commentor-detail">
            <h6 className="mb-0">
            <a className="media-heading text-dark">{review.user.name}</a>
            <Ratings ratings={review.rating} />
            </h6>
            <small className="text-muted">{review.role}</small>
          </div>
        </div>
        <small className="text-muted">{review.date} <br /> at {review.time}</small>
      </div>
      <div className="mt-3">
        <p className="text-muted mb-0">{review.comment}</p>
      </div>
      <Replies 
        review={review}
      />
      {
        isTutor() ?
          <>
            <button 
              className="btn btn-primary-text"
              data-bs-toggle="modal"
              data-bs-target={`#reply-${review.id}`}
            >Reply</button>
            <ReplyForm 
              id={`reply-${review.id}`}
              rating={review}
              {...others}
            />
          </>
        : null
      }
    </div>
  )
}

export default Review;