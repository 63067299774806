import React from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import {AppContext} from "App";

const usePageForm = () => {

  const history = useHistory();

  const { id } = useParams();

  const [isSubmitting, setIsSubmitting] = React.useState();
  const [isFetching, setIsFetching] = React.useState();
  const [isEditing, setIsEditing] = React.useState();

  const [page, setPage] = React.useState({
    name: "",
    body: ""
  });

  const {
    setCurrentUser,
    openModal,
    closeModal,
    openErrorDialog
  } = React.useContext(AppContext);

  let [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  const onEditorStateChange = (props) => {
    setEditorState(props);
  }

  React.useEffect(() => {
    if(!page) return;
    if(!isEditing) return;
    if(!page.body) return;
    const raw = JSON.parse(page.body);
    const eS = convertFromRaw(raw);
    setEditorState(EditorState.createWithContent(eS));
  }, [page]);


  const [asyncErrors, setAsyncErrors] = React.useState({});

  const fetchPage = async () => {
    try {
      setIsFetching(true);
      let url = `admin/pages/${id}/edit`;
      const res = await window.authFetch(url);
      if(res.status == 200){
        const body = await res.json();
        setPage(body);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }



  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      openModal();
      let body = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      let url = isEditing ? `admin/pages/${id}` : `admin/pages`;
      let method = isEditing ? "put" : "post";
      const res = await window.authFetch(url, {
        method,
        body: JSON.stringify({
          ...values,
          body
        })
      });
      if(res.ok){
        //const body = await res.json();
        history.push(`/admin/pages`);
      }else if (res.status == 422) {
        const body = await res.json();
        setAsyncErrors({...body});
      }else throw new Error();
    } catch (e) {
      console.log(e);
      openErrorDialog();
    } finally {
      setIsSubmitting(false);
      closeModal();
    }
  }

  React.useEffect(() => {
    const path = window.location.pathname;
    if(path.includes("edit")){
      setIsEditing(true);
      fetchPage();
    }
  }, []);


  return{
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    page,
    isFetching,
    editorState,
    onEditorStateChange
  }

}

export default usePageForm;
