import {Link, NavLink} from "react-router-dom";


const About = ({
    title,
    description,
    options,
    path,
    buttonText
}) => {
    return (
        <section className="section bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 section-title">
                        <h4 id="top" className="mb-4" style={{ lineHeight: "1" }}>{title}</h4>
                        <p>{description}</p>
                        <ul className="list-unstyled">
                            {
                                options.map((option, i) => (
                                    <li className="mt-2" key={i}>
                                        <span style={{ color: "green" }}>
                                            <i data-feather="check-circle"></i>
                                        </span>
                                        <span className="ms-2">{option}</span>
                                    </li>
                                ))
                            }
                        </ul>
                        <Link className="btn btn-primary mt-5" to={path}>{buttonText}</Link>
                    </div>
                    <div className="col-lg-1">

                    </div>
                    <div className="col-lg-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src="/images/cs/07.png" alt="" className="rounded img-fluid" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-between">
                                <img src="/images/blog/06.jpg" alt="" className="rounded img-fluid" />
                                <img src="/images/blog/04.jpg" alt="" className="rounded img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;