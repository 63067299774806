import React from "react";
import Navbar from "components/admin/Navbar.js";
import TextField from "components/BaseTextField";
import usePageForm from "./usePageForm";
import { Formik } from 'formik';
import * as Yup from "yup";
import { Editor } from "react-draft-wysiwyg";
import BackButton from "components/admin/BackButton";


const PageFormSchema = Yup.object().shape({
  name: Yup.string()
    .required(),
});

const PageForm = () => {

  const {
    asyncErrors,
    onSubmit,
    isSubmitting,
    isEditing,
    page,
    isFetching,
    editorState,
    onEditorStateChange,
  } = usePageForm();

  if (isFetching) return null;

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="card-name">{isEditing ? "Edit Page" : "New Page"}</h4>
                <Formik
                  initialValues={page}
                  validationSchema={PageFormSchema}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {
                    ({ handleSubmit, ...form }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">

                          <TextField
                            label="Name"
                            name="name"
                            form={form}
                            asyncErrors={asyncErrors}
                          />

                          <div>
                            <Editor
                              editorState={editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={onEditorStateChange}
                            />
                          </div>

                          <div className="col-lg-12 mb-0">
                            <div className="d-flex">
                              <button type="submit" className={`btn btn-primary ${isSubmitting ? 'disabled' : ''}`}>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }
                </Formik>
              </div>
            </div>
            <BackButton />
          </div>
        </div>
      </div>
    </>
  )
}

export default PageForm;
