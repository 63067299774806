
const Rating = ({ratings}) => (
  <ul className="list-unstyled h6 mb-0">
    {
      [...Array(5).keys()].map((i) => (
        <li className={`list-inline-item mb-0 ${(i + 1) <= ratings ? 'text-warning' : 'text-muted'}`}>
          <i className="mdi mdi-star"></i>
        </li>
      ))
    }
  </ul>
)

export default Rating;
