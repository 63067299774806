import React from "react";

const useOrders = () => {

  const [orders, setCourses] = React.useState([]);

  const fetchOrders = async () => {
    const res = await window.authFetch(`orders`);
    if(res.status == 200){
      const body = await res.json();
      setCourses(body);
    }
  }

  React.useEffect(() => {
    fetchOrders();
  }, []);

  return{
    orders
  }
}

export default useOrders;
