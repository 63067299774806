import Button from "@material-ui/core/Button";

const BaseButton = ({
    children,
    size = "12",
    ...others
}) => {
  return (
    <div className={`col-lg-${size} mb-0`}>
      <div className="d-grid">
        <button className="btn btn-primary" {...others} >{children}</button>
      </div>
    </div>
  )
}

export default BaseButton;
