import { AppContext } from "App";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

const useCourse = () => {

  const {
    id
  } = useParams();

  const {
    openModal,
    closeModal,
  } = useContext(AppContext);

  const [course, setCourse] = React.useState({
    sections: [],
    reviews: []
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchCourse = async () => {
    let token = localStorage.getItem("token");
    setIsLoading(true);
    const res = await fetch(`${window.host}/courses/${id}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
    if(res.status == 200){
      const body = await res.json();
      setCourse(body);
    }
    setIsLoading(false);
  }

  const onDownloadCertificate = async () => {
    let token = localStorage.getItem("token");
    openModal();
    const res = await fetch(`${window.host}/courses/${id}/cert`, {
      headers: {
        "Authorization": `Bearer ${token}`
      },
      method: "post",
    });
    if(res.status == 200){
      /*const body = await res.blob();
      const file = window.URL.createObjectURL(body);
      window.location.assign(file);
      */
    }
    closeModal();
  }

  React.useEffect(() => {
    fetchCourse();
  }, []);

  return{
    course,
    setCourse,
    isLoading,
    onDownloadCertificate,
  }
}

export default useCourse;
