import CardPage from "components/CardPage";
import { useFormik } from 'formik';
import * as yup from 'yup';
import useLogin from "hooks/useLogin";
import TextField from "components/BaseTextField";
import Button from "components/BaseButton";
import { useEffect } from "react";
import useSendEmail from "./useSendEmail";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
});

const SendEmail = () => {

    const {
        onSubmit,
        asyncErrors
    } = useSendEmail();


    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        window.feather.replace();
    }, []);

    return (
        <CardPage
            title="Send Mail"
        >
            <form className="login-form mt-4" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <TextField
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        form={formik}
                        Icon={"user"}
                        asyncErrors={asyncErrors}
                    />

                    <Button>
                        Send Email
                    </Button>
                </div>
            </form>
        </CardPage>
    );
}

export default SendEmail;