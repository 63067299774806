import React from "react";

const useCourses = () => {

  const [isSubmitting, setIsSubmitting] = React.useState();

  const [courses, setCourses] = React.useState([]);


  const fetchCourses = async (values) => {
    try {
      setIsSubmitting(true);
      const res = await window.authFetch(`admin/courses`);
      if(res.status == 200){
        const body = await res.json();
        setCourses(body);
      }
    } catch (e) {

    } finally {

    }
  }

  React.useEffect(() => {
    fetchCourses();
  }, []);


  return{
    fetchCourses,
    courses,
    setCourses,
  }

}

export default useCourses;
