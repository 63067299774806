import React from "react";

import { Link, NavLink } from "react-router-dom";

import Header from "../components/Header";
import Aside from "../components/Aside";
import Footer from "../components/Footer";

import useHome from "../hooks/useHome";

import Navbar from "components/Navbar";
import { Once, Categories } from "./Home";

const Info = ({ text, node }) => (
    <div className="section">
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-5">
                    <h1 className="text-end text-capitalize about-title">{node}</h1>
                </div>
                <div className="col-lg-5">
                    <p>
                        {text}
                    </p>
                </div>
            </div>
        </div>
    </div>
)

const About = () => {

    const {
        topCourses,
        popularCourses,
        categories
    } = useHome();

    React.useEffect(() => {
        window.topFunction();
        window.feather.replace();
    });

    return (
        <>
            <Navbar />
            <Header />
            <Info
                node={
                    <>Who <br />we are</>
                }
                text="Founded and established in 2020, STOR Institute exists to
                educate and train the next generation of world class
                accounting and finance professionals.
                The team consists of a group of highly driven professionals who have risen
                through the ranks in their respective roles and are keen
                democratize learning at a fraction of the cost."
            />
            <Aside />
            <Info
                node={
                    <>What <br />we do</>
                }
                text="                            Finance professionals of today who aspire to be world
                class will need to be well-rounded in their skill-set. To
                that end, a global and world-recognised accounting and
                finance qualification will set them on their way.
                We package what we know, in a practical manner, into
                high-quality bite-sized courses to equip you with all the
                knowledge and skills needed to effortlessly ace your
                papers."
            />
            <Categories categories={[
                "ACCA",
                "CFA",
                "CISI",
                "OBU"
            ]} />
            <Once />
            <Footer />
        </>
    )
}

export default About;
