import React from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import useLogin from "hooks/useLogin";
import {
  Link
} from "react-router-dom";

import TextField from "components/BaseTextField";
import Button from "components/BaseButton";
import LoginWith from "components/LoginWith";

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const Login = ({ history }) => {

  const {
    onSubmit,
    asyncErrors
  } = useLogin();


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });


  React.useEffect(() => {
    window.feather.replace();
  }, []);

  return(
    <>
      <section className="bg-half-170 d-table w-100" data-jarallax='{"speed": 0.5}' style={{"background": "url('images/crypto/stor_background.png') top", backgroundSize: "cover"}}>
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="card login-page bg-white shadow rounded border-0">
                <div className="card-body">
                  <h4 className="card-title text-center">Log In</h4>
                  <form className="login-form mt-4" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <TextField
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        form={formik}
                        Icon={"user"}
                        asyncErrors={asyncErrors}
                      />

                      <TextField
                        name="password"
                        label="Password"
                        placeholder="Password"
                        type="password"
                        form={formik}
                        Icon={"key"}
                        asyncErrors={asyncErrors}
                      />

                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p className="forgot-pass mb-3">
                            <Link to="/password/email" className="text-dark fw-bold">Forgot password ?</Link>
                          </p>
                        </div>
                      </div>

                      <Button>
                        Log In
                      </Button>

                      <div className="col-12 text-center">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">Don't have an account?</small>
                          <Link to={`/sign_up${window.location.search}`} className="text-dark fw-bold">Sign Up</Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login;
